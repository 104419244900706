import axios from "axios";
import store from '../../store'
import router from '../../router'

var api = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "",
  withCredentials: true
});

const publicEndpoints = [
  '/user',
  '/login',
  '/register',
  '/token/refresh',
  '/reset-password',
  '/verify-email',
  '/token/invalidate',
  `/organisations/${process.env.VUE_APP_ORG_ID}/device_templates?isActive=true`
]

api.interceptors.request.use(async function (config) {
  const now = Math.floor(Date.now() / 1000)
  console.log('Now: ',new Date(now * 1000).toLocaleString() )
  console.log('Exp: ',new Date(store.state.exp * 1000).toLocaleString() )
  if (!publicEndpoints.includes(config.url) && (now > store.state.exp)) {
    console.log('refreshing token...')
    try {
      await api.post("/token/refresh", {})
      try {
        const result = await api.get('/user')
        store.dispatch('saveExp', result.data.exp)
        return config
      } catch {
        router.push({name: 'login'})
        store.dispatch('setLoading', false);
      }
    } catch {
      try{
        await api.get('/token/invalidate')
        router.push({name: 'login'})
        store.dispatch('clearUser');
        store.dispatch('setLoading', false);
      } catch (e) {
        router.push({name: 'login'})
        store.dispatch('setLoading', false);
      }
    }
  } else {
    return config
  }
});

api.defaults.headers.patch['accept'] = 'application/json';
api.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json';

const patchHeaders = {headers: {'Content-Type': 'application/merge-patch+json'}}

export { api, patchHeaders };
