<template>
  <div v-show="requests.length > 0" :class="visible ? '' : 'closed'" class="z-10 fixed bottom-0 left-0 right-0 bg-white h-4/5 overflow-y-auto border-t border-gray300 rounded-t-md transition-all duration-300">
    <div @click="() => visible = !visible" class="flex justify-between items-center px-4 py-2 border-b border-gray-300 bg-gray-50">
      <p class="text-xs">{{ $t('request.processedOperations') }}: {{ requests.length }}</p>
      <div class="flex items-center space-x-2">
        <button>
          <ChevronDownIcon v-if="visible" class="w-4 h-4" />
          <ChevronUpIcon v-else class="w-4 h-4" />
        </button>

      </div>
    </div>
    {{  processing  }}
    <div class="p-4 space-y-4">
      <Request 
        v-for="(request, index) in requests"
        v-model="requests[index]"
        :ref="`requests`"
        :key="index"   
        :index="index"
        :requests-length="requests.length"
        @close="closeRequest(index)"
      />
    </div>
  </div>
</template>

<script>
import Request from './Request.vue';
import { mapState } from 'vuex'
import { ChevronUpIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { getIDB, getFromIDB} from '@/assets/js/helpers.js'

export default {
  components: {
    Request,
    ChevronUpIcon, ChevronDownIcon, XMarkIcon
  },
  emits: [],

  data() {
    return {
      db: null,
      visible: true
    }
  },
  computed: {
    ...mapState(['requests'])
    /*requests(){
      return [
        {
          action: 'insert',
          type: 'installation',
          status: 0
        }
      ]
    }*/
  },
  methods: {
    closeRequest(index) {
      this.$store.dispatch('removeRequest', index)
    },
    async checkPendingRequests () {
      this.db = await getIDB()
      this.pendingRequests = await getFromIDB(this.db, 'requests')
      if (this.pendingRequests && this.pendingRequests.length) {
        this.pendingRequests.forEach((request) => {
          this.$store.dispatch('addRequest', request)
        })
      }
    }
  },
  mounted() {
    console.log(this.requests)
    this.checkPendingRequests()
  },
  watch: {
    requests: {
      immediate: true,
      deep: true,
      handler () {
        this.visible = true
      }
    }
  }
};

</script>

<style lang="scss">
.closed {
  transform: translateY(100%);
}
</style>