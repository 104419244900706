<template>
  <Disclosure as="nav" class="sticky top-0 z-30 bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex items-stretch">
          <div class="flex-shrink-0 flex items-center">
            <div v-if="$route.name === 'installations'">
              <NavBack :heading="$t('home.installations')" :uri="`/`" />
            </div>
            <div v-else-if="$route.name === 'inspections'">
              <NavBack :heading="$t('home.inspections')" :uri="`/`" />
            </div>
            <div v-else-if="$route.name === 'services'">
              <NavBack :heading="$t('home.services')" :uri="`/`" />
            </div>
            <div v-else-if="$route.name === 'installation'">
              <NavBack :heading="$t('home.installationPerview')" :uri="`/installations`" />
            </div>
            <div v-else-if="$route.name === 'support'">
              <NavBack :heading="$t('home.support')" :uri="`/`" />
            </div>
            <div v-else-if="$route.name === 'edit-profile'">
              <NavBack :heading="$t('home.editProfile')" :uri="`/`" />
            </div>
            <Logo v-else />

            <!--<img
              class="block lg:hidden h-8 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            />
            <img
              class="hidden lg:block h-8 w-auto"
              src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
              alt="Workflow"
            />-->
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link
              v-for="(link, index) in navigationLinks"
              :key="index"
              :to="link.route"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              {{ link.name }}
            </router-link>
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <span :title="$t('home.pendingRequest')" v-if="pendingRequests" @click="notifyPending" class="relative mr-2">
            <ArrowRightIcon class="h-6 w-6" aria-hidden="true" />
            <span class="absolute -bottom-2 -right-1 w-4 h-4 text-xs bg-yellow-500 text-white rounded-full flex justify-center items-center">{{ pendingRequests }}</span>
          </span>

          <div v-if="role.name == 'superadmin'" class="ml-2">
            <OrganisationSelect class="w-48" />
          </div>
          <!--<LangSwitcher />-->
          <LangSwitcher class="px-2" btnClass="shadow-sm rounded-md ring-1 justify-center" />
          <!-- Profile dropdown -->
          <Menu as="div" class="ml-3 relative">
            <div>
              <MenuButton
                class="
                  bg-white
                  rounded-full
                  flex
                  text-sm
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                "
              >
                <span class="sr-only">Open user menu</span>
                <UserCircleIcon class="h-8 w-8 text-ifgray-dark" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="
                  origin-top-right
                  absolute
                  right-0
                  mt-2
                  rounded-md
                  shadow-lg
                  bg-white
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                "
                :class="role.name == 'ASP'? 'pb-1' : 'py-1'"
              >
                <MenuItem v-if="role.name == 'ASP'">
                  <span class="isolate inline-flex rounded-t-md shadow-sm">
                    <button @click="toggleAspView(false)" type="button" :class="!aspView ? 'text-white bg-ifgreen' : 'text-gray-700 bg-white hover:bg-gray-50'" class="relative inline-flex items-center rounded-tl-md border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus:border-ifgreen focus:outline-none focus:ring-1 focus:ring-ifgreen">Instalator</button>
                    <button @click="toggleAspView(true)" type="button" :class="aspView ? 'text-white bg-ifgreen' : 'text-gray-700 bg-white hover:bg-gray-50'" class="relative -ml-px inline-flex items-center rounded-tr-md border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus:border-ifgreen focus:outline-none focus:ring-1 focus:ring-ifgreen">Admin</button>
                  </span>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <router-link to="/edit-profile"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700 whitespace-nowrap',
                    ]"
                  >
                    {{ $t('home.editProfile') }}
                  </router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    @click="signOut"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block w-full text-left px-4 py-2 text-sm text-gray-700 whitespace-nowrap',
                    ]"
                    >{{ $t('home.logout') }}</button
                  >
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <router-link v-if="this.pendingServices && !isAdmin" to="/services" class="mr-2 relative" :class="animationClass">
            <CogIcon class="h-6 w-6" aria-hidden="true" />
            <span class="absolute -bottom-2 -right-1 w-4 h-4 text-xs bg-yellow-500 text-white rounded-full flex justify-center items-center">{{ this.pendingServices }}</span>
          </router-link>
          <router-link v-if="this.incomingInspections && !isAdmin" to="/inspections" class="mr-2 relative" :class="animationClass">
            <BellIcon class="h-6 w-6" aria-hidden="true" />
            <span class="absolute -bottom-2 -right-1 w-4 h-4 text-xs bg-yellow-500 text-white rounded-full flex justify-center items-center">{{ this.incomingInspections }}</span>
          </router-link>
          <span :title="$t('home.pendingRequest')" v-if="pendingRequests && !isAdmin" @click="notifyPending" class="relative mr-2">
            <ArrowRightIcon class="h-6 w-6" aria-hidden="true" />
            <span class="absolute -bottom-2 -right-1 w-4 h-4 text-xs bg-yellow-500 text-white rounded-full flex justify-center items-center">{{ pendingRequests }}</span>
          </span>
          <DisclosureButton
            class="
              inline-flex
              items-center
              justify-center
              p-2
              rounded-md
              text-gray-400
              hover:text-gray-500
              hover:bg-gray-100
              focus:outline-none
              focus:ring-2 focus:ring-inset focus:ring-ifgreen
            "
            @click="open = !open"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel v-if="open" static class="sm:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <router-link
          v-for="(link, index) in navigationLinks"
          :key="index"
          :to="link.route"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
        >
          {{ link.name }}
        </router-link>
      </div>
      <div class="pt-4 pb-4 border-t border-gray-200">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <UserCircleIcon class="h-10 w-10" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">{{ this.user?.name }}</div>
            <div class="text-sm font-medium text-gray-500">{{ this.user?.email }}</div>
          </div>
          <button
            class="
              ml-auto
              flex-shrink-0
              bg-white
              p-1
              rounded-full
              text-gray-400
              hover:text-gray-500
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
            "
          >
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-3 space-y-1">
          <span  class="w-full px-4 isolate inline-flex rounded-md shadow-sm">
            <button @click="toggleAspView(false)" type="button" :class="!aspView ? 'text-white bg-ifgreen' : 'text-gray-700 bg-white hover:bg-gray-50'" class="relative w-1/2 justify-center inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus:border-ifgreen focus:outline-none focus:ring-1 focus:ring-ifgreen">{{$t("installation.installer")}}</button>
            <button @click="toggleAspView(true)" type="button" :class="aspView ? 'text-white bg-ifgreen' : 'text-gray-700 bg-white hover:bg-gray-50'" class="relative w-1/2 justify-center -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium focus:z-10 focus:border-ifgreen focus:outline-none focus:ring-1 focus:ring-ifgreen">Admin</button>
          </span>
          <router-link to="/edit-profile" class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
            {{ $t('home.editProfile') }}
          </router-link>
          <button
            @click="signOut"
            class="
              block
              w-full
              px-4
              py-2
              text-base
              text-left
              font-medium
              text-gray-500
              hover:text-gray-800
              hover:bg-gray-100
            "
            >{{ $t('home.logout')}}
          </button>
        </div>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div class="flex items-center">
          <LangSwitcher class="w-full" dropdownClass="w-full" btnClass="justify-between"/>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { BellIcon, CogIcon, ArrowRightIcon, Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { UserCircleIcon } from "@heroicons/vue/24/solid";
// import { Auth } from "aws-amplify";
import { api } from "@/assets/js/api-client.js";
import Logo from '@/components/layout/Logo.vue';
import NavBack from '@/components/layout/NavBack.vue';
import LangSwitcher from '@/components/layout/LangSwitcher.vue';
import OrganisationSelect from '@/components/admin/OrganisationSelect.vue'
import { mapState } from 'vuex'



export default {
  components: {
    Logo,
    NavBack,
    OrganisationSelect,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    CogIcon,
    Bars3Icon,
    XMarkIcon,
    UserCircleIcon,
    ArrowRightIcon,
    LangSwitcher
  },
  emits: ['check-pending'],
  props: {
    pendingRequests: Number
  },
  data () {
    return {
      open: false,
      animationClass: 'animate-bounce'
    }
  },
  computed: {
    ...mapState(['user', 'role', 'aspView', 'incomingInspections', 'pendingServices']),
    navigationLinks () {
      return [
        {route: '/', name: `${this.$t("menu")}`, role: ['installer', 'ASP']},
        {route: '/installations', name: `${this.$t("home.installations")}`, role: ['installer', 'ASP']},
        {route: '/inspections', name: `${this.$t("home.inspections")}`, role: ['installer', 'ASP']},
        {route: '/services', name: `${this.$t("home.services")}`, role: ['installer', 'ASP']},
        {route: '/support', name: `${this.$t("home.support")}`, role: ['installer', 'ASP']},
        {route: '/production', name: 'Produkcja', role: ['production', 'superadmin']},
        {route: '/admin/devices', name: 'Urządzenia', role: ['superadmin']},
        {route: '/admin/installations', name: `${this.$t("home.installations")}`, role: ['superadmin', 'admin', 'ASP']},
        {route: '/admin/services', name: `${this.$t("home.services")}`, role: ['superadmin', 'admin', 'ASP']},
        {route: '/admin/users', name: `${this.$t("home.users")}`, role: ['superadmin', 'admin']},
        {route: '/admin/settings', name: `${this.$t("home.settings")}`, role: ['superadmin', 'admin']},
      ].filter((link) => {
        if (this.role.name == 'ASP') {
          if (this.aspView) return link.role.includes('ASP') && link.role.includes('admin')
          else return link.role.includes('ASP') && link.role.includes('installer')
        } else {
          return link.role.includes(this.role.name)
        }

      })
    },
    isAdmin () {
      return ['superadmin', 'admin'].includes(this.role.name)
    }
  },
  methods: {
    hideMenu () {
      this.open = false
    },
    notifyPending () {
      this.$emit('check-pending')
      this.$notify({
        title: `${this.$t("pendingNote")} ${this.pendingRequests}`,
        type: "warning",
        duration: 5000
      })
    },
    toggleAspView (enabled) {
      this.$store.dispatch('setAspView', enabled)
      if (enabled) this.$router.push({name: 'admin-installations'})
      else this.$router.push({name: 'home'})
    },
    async signOut() {
      this.$store.dispatch('setLoading', true);
      try {
        await api.get('/token/invalidate')
        console.log("signOut");
        this.$router.push({ name: "login" });
        this.$store.dispatch('clearUser');
        this.$store.dispatch('setLoading', false);
      } catch (error) {
        console.log('error signing out: ', error);
        this.$store.dispatch('setLoading', false);
      }
    }
  },
  created() {
    this.$store.dispatch('getNotifications')
    setTimeout(() => {
      this.animationClass = ''
    }, 1000 * 60)
  },
  watch: {
    $route(to) {
      this.hideMenu()
      if (to.name === 'inspections' || to.hash === '#inspections') {
        this.animationClass = ''
      }
      // console.log(to.name, to.hash)
    }
  }
};
</script>
