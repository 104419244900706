<template>
    <div class="flex flex-grow">
        <router-link :to="`${uri}`">
            <ArrowLeftIcon class="w-6" />
        </router-link>
        <div class="px-4">{{heading}}</div>
        
    </div>
</template>
<script>
import { ArrowLeftIcon } from "@heroicons/vue/24/outline";


export default {
  props: {
    heading: String,
    uri: String,
  },
  components: {
    ArrowLeftIcon,
  },
  
};

</script>