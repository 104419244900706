<template>
  <div :class="disabled && 'opacity-70'">
    <button v-if="!compact" @click="startScan" type="button" class="inline-flex w-full items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
      <QrCodeIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      {{ $t("home.barcodeScanner.scanBarcode") }}
    </button>
    <div v-if="!compact" class="my-4">
      <div class="relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="px-2 bg-white text-gray-500">
            {{ text }}
          </span>
        </div>
      </div>
    </div>
    <div :class="disabled && 'pointer-events-none'">
      <label v-if="compact && label" class="block text-sm font-medium text-gray-700 mb-1">{{ label }}</label>
      <div class="flex rounded-md shadow-sm">
        <div class="relative flex items-stretch flex-grow focus-within:z-10">
          <input :value="modelValue" @input="handleInput" type="text" inputmode="numeric" name="serial-number" id="serial-number" :class="!showEnterButton ? 'rounded-r-md' : ''" class="focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-l-md sm:text-sm border-gray-300 placeholder-gray-400" :placeholder="placeholder" />
        </div>
        <button v-if="compact" @click="startScan" :class="compact ? 'px-3 py-2' : 'px-4 py-3'" class="-ml-px relative inline-flex items-center space-x-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <QrCodeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
        <button v-if="showEnterButton && !compact" @click="$emit('scan-completed')" :class="compact ? 'px-3 py-2' : 'px-4 py-3'" class="-ml-px relative inline-flex items-center space-x-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <ArrowRightIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
  <div v-if="showCanvas" class="fixed top-0 left-0 checked-full-vh w-screen z-10">
    <video id="video" class="h-full w-full bg-black"></video>
    <div class="absolute left-0 w-full" style="top: 64px">
      <div class="px-6 py-2 w-full border-b border-gray-300 text-center text-sm font-medium text-gray-700 bg-gray-50">
        {{ $t("home.barcodeScanner.scanBarcodeInfo") }}

      </div>
    </div>
    <div class="absolute bottom-0 left-0 w-full">
      <div class="px-6 py-2 w-full border-b border-gray-300 text-center text-sm font-medium text-gray-700 bg-gray-50">
        {{ selectedDeviceLabel }}
      </div>
      <div class="flex items-bottom">
        <button @click="stopScan" type="button" class="inline-flex flex-grow items-center justify-center px-6 py-3 text-base font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
           {{ $t("cancel") }}
        </button>
        <button v-if="showCameraSwitch" @click="switchCamera" type="button" class="border-l border-gray-300 inline-flex w-14 items-center justify-center px-3 py-3 text-base font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
          <CameraIcon class="w-6 h-6" />
        </button>
      </div>
    </div>
  </div>
  <Modal
    ref="modal"
    :title="$t('home.barcodeScanner.modalTitle')"
    :text="$t('home.barcodeScanner.modalText')"
    cancel-button-label="OK"
  >
  </Modal>
</template>

<script>
import { QrCodeIcon, ArrowRightIcon, CameraIcon } from '@heroicons/vue/24/outline'
import { BrowserBarcodeReader, BarcodeFormat, DecodeHintType } from '@zxing/library';
import vhCheck from 'vh-check'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: "BarcodeReader",
  components: {
    QrCodeIcon,
    ArrowRightIcon,
    CameraIcon,
    Modal
  },
  emits: ['update:modelValue','scan-completed'],
  props: {
    modelValue: String,
    label: String,
    text: {
      type: String,
      default: "albo wpisz numer seryjny urządzenia"
    },
    placeholder: {
      type: String,
      default: "numer seryjny"
    },
    showEnterButton: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      codeReader: null,
      selectedDeviceId: null,
      selectedDeviceLabel: null,
      showCanvas: false,
      showCameraSwitch: false,
      isIos: false,
      cameraIndex: 0,
      videoDevices: null
    }
  },
  mounted () {
    vhCheck()
    this.isIos = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    const hints = new Map();
    const formats = [BarcodeFormat.CODE_128];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    hints.set(DecodeHintType.TRY_HARDER, false);
    this.codeReader = new BrowserBarcodeReader({hints: hints})
    console.log('ZXing code reader initialized')
    this.codeReader.getVideoInputDevices()
      .then((videoInputDevices) => {
          this.videoDevices = videoInputDevices
          if (this.videoDevices.length > 1) {
            this.showCameraSwitch = true
            const label = localStorage.getItem('defaultCameraLabel')
            if (label) {
              const device = this.videoDevices.find((device) => { return device.label === label })
              this.selectedDeviceId = device.deviceId
              this.selectedDeviceLabel = device.label
            }
            else {
              this.selectedDeviceId = this.videoDevices[this.cameraIndex].deviceId
              this.selectedDeviceLabel = this.videoDevices[this.cameraIndex].label
            }
          }
          else {
            this.selectedDeviceLabel = this.videoDevices[this.cameraIndex].label
          }
      })
      .catch((err) => {
          console.error(err)
          this.$notify({
            title: `${this.$t("home.barcodeScanner.errorTitle2")}`,
            text: `${this.$t("home.barcodeScanner.errorMessage2")}`,
            type: 'error',
          });
      })
  },
  methods: {
    handleInput (e) {
      this.$emit('update:modelValue', e.target.value);
    },
    startScan () {
      if (this.disabled) {
        this.$notify({
          title: `${this.$t("home.barcodeScanner.warningTitle")}`,
          text: `${this.$t("home.barcodeScanner.warningMessage")}`,
          type: 'warning',
          duration: 5000
        });
        return
      }
      if (this.videoDevices.length > 1 && !this.selectedDeviceLabel ){
        this.$refs.modal.show()
      }
      this.showCanvas = true
      this.codeReader.decodeOnceFromVideoDevice(this.selectedDeviceId, 'video')
        .then((result) => {
          console.log(result)
          this.codeReader.reset()
          this.showCanvas = false
          if (!this.isIos) window.navigator.vibrate(200)
          this.$emit('update:modelValue', result.text)
          this.$emit('scan-completed')
      }).catch((err) => {
          console.error(err)
          if (String(err).indexOf("Video stream has ended") === -1) {
            this.$notify({
              title: `${this.$t("home.barcodeScanner.errorTitle")}`,
              text: `${this.$t("home.barcodeScanner.errorMessage")}`,
              type: 'error',
              duration: 15000
            });
          }
      })
    },
    stopScan () {
      this.showCanvas = false
      this.codeReader.reset()
    },
    switchCamera () {
      this.cameraIndex = this.cameraIndex === (this.videoDevices.length - 1) ? 0 : this.cameraIndex + 1
      this.selectedDeviceId = this.videoDevices[this.cameraIndex].deviceId
      this.selectedDeviceLabel = this.videoDevices[this.cameraIndex].label
      localStorage.setItem('defaultCameraLabel', this.videoDevices[this.cameraIndex].label)
      this.codeReader.reset()
      setTimeout(() => {
        this.startScan()
      }, 500)
    }
  }
};
</script>

<style scoped lang="scss">
  .checked-full-vh{
    height: calc(100vh - var(--vh-offset, 0px));
  }
</style>


