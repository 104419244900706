<template>
  <div class="border border-gray-200 rounded-md shadow overflow-hidden">
    <div class="p-4 border-b border-gray-200 flex justify-between text-xs" :class="request.error ? 'bg-red-50' : (request.status == 7 ? 'bg-green-50' : '')">
      <p>{{ index + 1 }}/{{ requestsLength }} - {{ request.action }} {{ request.type }}</p><p class="font-bold">{{ statuses[request.status]?.label }}</p>
    </div>
    <div v-if="request.status > 0" class="p-4 space-y-3">
      <div :class="`text-sm ${request.status < 1 ? 'opacity-50' : ''}`">
        <div class="flex justify-between">
          <p>1. {{ $t('request.sendingData') }}</p>
          <CogIcon v-if="request.status == 1" class="h-4 w-4 animate-spin" />
          <XMarkIcon v-else-if="request.status == 2" class="h-4 w-4 text-red-500" />
          <CheckIcon v-else-if="request.status > 2" class="h-4 w-4 text-green-500" />  
        </div>
        <div v-if="request.error && request.status == 2" class="mt-1 pl-4 text-xs text-red-500">{{ request.error.response?.data?.detail ?? request.error.response?.data ?? request.error.message }}</div>
      </div>
      <div :class="`text-sm ${request.status < 3 ? 'opacity-50' : ''}`">
        <div class="flex justify-between">
          <p>2. {{ $t('request.sendingFiles') }}</p>
          <CogIcon v-if="request.status == 3" class="h-4 w-4 animate-spin" />
          <XMarkIcon v-else-if="request.status == 4" class="h-4 w-4 text-red-500" />
          <CheckIcon v-else-if="request.status > 4" class="h-4 w-4 text-green-500" />  
        </div>
        <div v-if="request.status > 2">
          <div v-if="filesToUpload && filesToUpload.length" class="overflow-hidden mt-2 bg-white shadow sm:rounded-md border border-gray-200">
            <ul role="list" class="divide-y divide-gray-200">
              <FileUploader 
                v-for="file in filesToUpload" 
                :key="file.id"
                :file="file"
                mode="operation-image"
                :organisation="organisation"
                @success="markAsSent"
                @error="throwUploadError"
              />
            </ul>
          </div>
          <p v-else class="mt-1 pl-4 text-xs">{{ $t('request.noFilesToSend') }}</p>
        </div>
        <div v-if="request.error && request.status == 4" class="mt-1 pl-4 text-xs text-red-500">{{ request.error.response?.data?.detail ?? request.error.response?.data ?? request.error.message }}</div>
      </div>
      <div :class="`text-sm ${request.status < 5 ? 'opacity-50' : ''}`">
        <div class="flex justify-between">
          <p>3. {{ $t('request.sendingEmail') }}</p>
          <CogIcon v-if="request.status == 5" class="h-4 w-4 animate-spin" />
          <XMarkIcon v-else-if="request.status == 6" class="h-4 w-4 text-red-500" />
          <CheckIcon v-else-if="request.status > 6" class="h-4 w-4 text-green-500" />  
        </div>
        <div v-if="request.status > 4 && !request.emailRequest">
          <p class="mt-1 pl-4 text-xs">{{ $t('request.noEmailsToSend') }}</p>
        </div>
        <div v-if="request.error && request.status == 6" class="mt-1 pl-4 text-xs text-red-500">{{ request.error.response?.data?.detail ?? request.error.response?.data ?? request.error.message }}</div>
      </div>
    </div>
    <div class="flex divide-x divide-gray-200 border-t border-gray-200">
      <div v-if="[2,4,6].includes(request.status)" class="w-1/2 flex bg-red-500">
        <button @click="abort" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-white font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none">
          {{ $t('request.abort') }}
        </button>
      </div>
      <div v-if="[2,4,6].includes(request.status)" class="w-1/2 flex">
        <button @click="processRequest" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none">
          {{ $t('request.retry') }}
        </button>
      </div>
      <div v-if="request.status == 7" class="w-full flex">
        <button @click="() => $emit('close')" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none">
          {{ $t('request.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from '@/components/layout/FileUploader.vue'
import { getIDB, addToIDB, deleteFromIDB, base64ToFile, createFiles} from '@/assets/js/helpers.js'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { CogIcon, CheckIcon, XMarkIcon, ArrowRightIcon} from '@heroicons/vue/24/outline'

export default {
  components: {
    CogIcon, CheckIcon, XMarkIcon, ArrowRightIcon,
    FileUploader
  },
  emits: ['update:modelValue', 'done', 'close'],
  props: {
    modelValue: Object,
    requestsLength: Number,
    index: Number
  },
  data() {
    return {
      db: null,
      request: {},
      filesSent: [],
      filesToUpload: null
    }
  },
  computed: {
    statuses () {
      return [
        {name: 'pending', label: this.$t('request.pending')},
        {name: 'sending_data', label: this.$t('request.sendingData')},
        {name: 'sending_data|error', label: this.$t('request.sendingDataError')},
        {name: 'sending_files', label: this.$t('request.sendingFiles')},
        {name: 'sending_files|error', label: this.$t('request.sendingFilesError')},
        {name: 'sending_email', label: this.$t('request.sendingEmails')},
        {name: 'sending_email|error', label: this.$t('request.sendingEmailsError')},
        {name: 'done', label: this.$t('request.done')}
      ]
    }
  },
  methods: {
    async processRequest() {
      if (!this.request.id) {
        const e = await addToIDB(this.db, 'requests', this.sanitizeRequest(this.request))
        this.request["id"] = e.req.target.result
        console.log("fallback request saved")
        this.$notify({
          title: this.$t('request.backupSaved'),
          type: 'success'
        })
      }

      console.log(this.request.status)
      switch (this.request.status) {
        case 0:
        case 1:
        case 2:
          await this.sendData()
          break
        case 3:
        case 4:
          await this.sendFiles()
          break
        case 5:
        case 6:
          await this.sendEmail()
          break
      }
      
    },
    async sendData() {
      try {
        this.request.status = 1
        const method = this.request.action == 'insert' ? 'post' : 'patch'
        const url =  this.request.action == 'insert' ? this.request.endpoint : `${this.request.endpoint}/${this.request.data.id}`
        const headers = this.request.action == 'insert' ? {} : patchHeaders.headers

        let result = await api.request({method, url, data: this.request.data, headers})

        console.log(result)
        this.request.deviceId = result.data.customDevice.id

        await addToIDB(this.db, 'requests', this.sanitizeRequest(this.request))
        this.$store.dispatch('getNotifications')
        await this.sendFiles()

      } catch (error) {
        console.log(error)
        this.request.status = 2
        this.request.error = error
        await addToIDB(this.db, 'requests', this.sanitizeRequest(this.request))
        this.$emit('done')
      }
    },
    prepareFiles () {
      const date = this.request.type == 'inspection' ? this.request.executionTime : (this.request.type == 'service' ? this.request.startDate : null)  
      const files = this.request.files ? createFiles(this.request.files, this.request.deviceId, null, date) : []
      this.filesToUpload = []
      this.$nextTick(() => {
        this.filesToUpload = files.map(file => {
          return base64ToFile(file.base64, file.filename, file.type)
        })
      })
    },
    sendFiles() {
      this.request.status = 3
      console.log(this.request.status)
      this.prepareFiles()
      this.$nextTick(() => {
        console.log(this.filesToUpload.length)
        if (this.filesToUpload.length == 0) {
          this.sendEmail()
        }
      })
    },
    markAsSent(file) {
      this.filesSent.push(file)
    },
    async throwUploadError() {
      console.log('images error')
      await addToIDB(this.db, 'requests', this.sanitizeRequest(this.request))
      this.request.status = 4
      this.request.error = {response: {data: this.$t('request.sendingFilesError')}}
      this.$emit('done')
    },
    async sendEmail () {
      this.request.status = 5
      if (this.request.emailRequest) {
        const { action, data } = this.request.emailRequest
        try {
          const result = await api.post('/send-email', {action, data})
          console.log(result)
          this.$notify({
            title: "Sukces",
            text: `${action === 'service_card' ? this.$t('service.serviceCard') : this.$t('inspection.inspectionCard')} ${this.$t('request.cardSentSuccessfully')}`,
            type: 'success'
          })
        } catch(error) {
          this.request.status = 6
          this.request.error = error
          await addToIDB(this.db, 'requests', this.sanitizeRequest(this.request))
          this.$emit('done')
        }
      }
      this.markAsDone()
    },
    markAsDone() {
      this.request.status = 7
      this.request.error = null
      console.log('done')
      deleteFromIDB(this.db, 'requests', this.request.id);
      this.$emit('done')
    },
    sanitizeRequest(request) {
      return JSON.parse(JSON.stringify(request))
    },
    abort () {
      if (confirm(this.$t('request.deleteWarning'))) {
        deleteFromIDB(this.db, 'requests', this.request.id)
        this.$emit('close')
      }
    }
  },
  async mounted() {
    this.db = await getIDB()
    if (this.request.status == 0) this.processRequest()
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.request = this.modelValue
      }
    },
    value: {
      deep: true,
      handler () {
        this.$emit('update:modelValue', this.request)
      }
    },
    filesSent: {
      deep: true,
      handler () {
        if (this.filesSent.length === this.filesToUpload.length) {
          this.sendEmail()
        }
      }
    },
  }
};

</script>