<template>
  <div id="app" :class="requests.length ? 'pb-8' : ''">
    <Navigation
      v-if="showNav"
      ref="nav"
      :pendingRequests="requests && requests.length"
      @check-pending="() => $refs.requestProcessor.visible = true"
    />
    <router-view v-if="dependenciesLoaded" />
    <RequestProcessor v-if="user" ref="requestProcessor" />
    <Loader :open="loading" />
    <notifications class="z-index-50" position="bottom right" :width="isMobile ? '100%' : '400px'" :duration="5000">
      <template v-slot:body="props">
        <NofiticationCard
          :title="props.item.title"
          :text="props.item.text"
          :type="props.item.type"
          :close="props.close"
         />
      </template>
    </notifications>
    <Modal
      ref="modal"
      title="Weryfikacja"
      :text="modalText"
    >
      <button
        type="button" class="modal-action-button"
        @click="confirmIncognitoRequest"
      >
        Tak, wyślij z mojego konta
      </button>
      <button
        type="button" class="modal-action-button mt-3"
        @click="rejectIncognitoRequest"
      >
        Nie, nie wysyłaj
      </button>
    </Modal>

    <Modal
      ref="noAspModal"
      :title="$t('chooseASP')"
      :text="$t('chooseASPInfo')"
      :cancel-button-label="$t('notNow')"
    >
      <button
        type="button" class="modal-action-button"
        @click="chooseAsp"
      >
        {{ $t('chooseASP') }}
      </button>
    </Modal>

    <Modal
      ref="tempErrorModal"
      :title="$t('emergencyMode')"
    >
      <button
        type="button" class="modal-action-button"
        @click="copyError"
      >
        {{ $t('copyErrorData') }}
      </button>
      <button
        type="button" class="modal-action-button mt-3"
        @click="clearDb"
      >
        {{ $t('clearMalformedData') }}
      </button>
    </Modal>
  </div>
</template>

<script>
import Navigation from "@/components/layout/Navigation.vue";
import RequestProcessor from "./components/layout/RequestProcessor.vue";
import NofiticationCard from "@/components/layout/NotificationCard.vue";
import Loader from '@/components/layout/Loader.vue';
import Modal from '@/components/layout/Modal.vue'
import { deleteFromIDB } from '@/assets/js/helpers.js'
import { mapState } from 'vuex'

export default {
  components: {
    Navigation,
    NofiticationCard,
    Loader,
    Modal,
    RequestProcessor
  },
  data () {
    return {
      isMobile: false,
      db: null,
      pendingRequests: null,
      currentIncognitoRequestIndex: null,
      currentPendingRequest: null,
      modalText: ''
    }
  },
  computed: {
    ...mapState(['user', 'role', 'loading', 'installations', 'deviceTemplates', 'organisation', 'asps', 'aspModalSwitch', 'requests']),
    showNav() {
      return (
        this.user &&
        this.$route.name !== "login" &&
        this.$route.name !== "register" &&
        this.$route.name !== "password-recovery"
      )
    },
    dependenciesLoaded () {
      const dependenciesLoaded = !this.user || this.deviceTemplates != null
      this.$store.dispatch('setLoading', !dependenciesLoaded)
      return dependenciesLoaded
    },
    isIos() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    }
  },
  methods: {
    copyError () {
      const preparedRequests = this.pendingRequests.map((request) => {
        delete request.files
        return request
      })
      const error = JSON.stringify(preparedRequests)
      console.log(error)
      navigator.clipboard.writeText(error).then(() => {
        this.$notify({
          title: this.$t('public.success'),
          text: this.$t('copyingSucceed'),
          type: "success"
        })
      }, () => {
        this.$notify({
          title: this.$t('public.error'),
          text: this.$t('copyingFailed'),
          type: "error"
        })
      });
    },
    clearDb () {
      this.pendingRequests.forEach((request) => {
        deleteFromIDB(this.db, 'requests', request.id);
        const index = this.pendingRequests.findIndex((lr) => {return lr.id === request.id})
        this.pendingRequests.splice(index, 1)
      })
      this.$notify({
        title: this.$t('public.success'),
        text: this.$t('malformedDataDeletedSuccessfully'),
        type: "success"
      })
      this.$refs.tempErrorModal.close()
    },
    detectMobile () {
      this.isMobile = window.innerWidth < 640
    },
    showInstallBannerIos () {
      // Detects if device is in standalone mode
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

      // Checks if should display install popup notification:
     if (this.isIos && !isInStandaloneMode()) {
        this.$notify({
          title: `${this.$t("installAppTitle")}`,
          text: `${this.$t("installAppMessage")}`,
          type: "install",
          duration: 10000
        })
      }
    },
    chooseAsp () {
      this.$refs.noAspModal.close()
      this.$router.push({name: 'edit-profile', query: {chooseASP: true}})
    }
  },
  async mounted() {
    this.detectMobile()
    this.showInstallBannerIos()
    window.addEventListener('resize', this.detectMobile)
    Notification.requestPermission();
  },
  watch: {
    user: {
      immediate: true,
      async handler () {
        if (this.user) {
          if (!this.deviceTemplates) await this.$store.dispatch('getDeviceTemplates')
          this.$store.dispatch('checkAsp')
        }
      }
    },
    aspModalSwitch () {
      this.$refs.noAspModal.show()
    }
  }
};
</script>
