<template>
  <div v-if="deviceTemplates" class="home">
    <HomeNavigation />
    <span class="block w-full h-px bg-gray-300 my-3"></span>
    <div v-if="deviceTemplates" class="max-w-7xl mx-auto px-4 py-3 sm:p-6 lg:p-8">
      <div v-if="deviceTemplatesOptions.length > 1" class="mb-4">
        <NativeSelect
          v-model = "deviceTemplateId"
          :placeholder="$t('home.deviceSearch')"
          :options="deviceTemplatesOptions"
          :icon="true"
        />
      </div>
      <BarcodeScanner
        :disabled="deviceTemplates.length > 1 && !deviceTemplateId"
        v-model="serialNumber"
        :text="$t('home.barcodeScanner.insertSerialNumber')"
        :placeholder="$t('home.barcodeScanner.placeholder')"
        @scan-completed="checkSerialNumber"
      />
    </div>

    <Modal
      ref="modal"
      :title="serialNumber"
      :text="modalText"
      :cancel-button-label="$t('cancel')"
    >
      <button
        v-if="modalAction !== 'mounted-device'"
        type="button" class="modal-action-button"
        @click="startInstallation"
      >
        {{ $t("device.installation") }}
      </button>
      <button v-if="modalAction === 'mounted-device'" type="button" class="mt-3 modal-action-button" @click="goToInspection">
        {{ $t("device.inspection") }}
      </button>
      <button v-if="modalAction === 'mounted-device'" type="button" class="mt-3 modal-action-button" @click="goToService">
        {{ $t("device.service") }}
      </button>
    </Modal>

    <Modal
      ref="serviceModal"
      :title="serialNumber"
      :text="$t('device.notFinished')"
    >
      <button
        type="button" class="modal-action-button"
        @click="$router.push({path: `/services/${serviceId}`})"
      >
        {{ $t("yes") }}, {{ $t("device.finishService") }}
      </button>
      <button
        type="button" class="mt-3 modal-action-button"
        @click="$router.push({path: `/services/new/device/${device.id}`})"
      >
        {{ $t("no") }}, {{ $t("device.startNewService") }}
      </button>
    </Modal>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeNavigation from '@/components/layout/HomeNavigation.vue';
import BarcodeScanner from '@/components/inputs/BarcodeScanner.vue';
import NativeSelect from '@/components/inputs/NativeSelect.vue';
import Modal from '@/components/layout/Modal.vue';
import { mapState } from 'vuex'
import { api } from '@/assets/js/api-client.js'

export default {
  components: {
    HomeNavigation,
    BarcodeScanner,
    NativeSelect,
    Modal
  },
  name: "Home",
  data () {
    return {
      serialNumber: '',
      serviceId: '',
      modalText:'',
      modalAction: '',
      deviceTemplateId: process.env.VUE_APP_IFDEVICE_TEMPLATE_ID
    }
  },
  computed: {
    ...mapState(['device', 'user', 'installations', 'services', 'deviceTemplates', 'specialDeviceTemplate', 'organisation']),
    deviceTemplatesOptions () {
      return this.deviceTemplates
        .filter(template => {return template.isActive && this.user.availableTemplates.includes(`/device_templates/${template.id}`)})
        .map(template => {return {name: template.nameTranslations?.[this.$i18n.locale] ? template.nameTranslations[this.$i18n.locale] : template.name, value: template.id}})
    },
    deviceTemplate () {
      return this.deviceTemplates.find(template => {return template.id == this.deviceTemplateId})
    },
  },
  mounted () {
    if (this.deviceTemplatesOptions[0]) this.deviceTemplateId = this.deviceTemplatesOptions[0].value
  },
  methods: {
    async checkSerialNumber () {
      console.log(this.deviceTemplate)
      if (this.serialNumber === ""){
        this.$notify({
          title: `${this.$t("home.barcodeScanner.errorTitle")}`,
          text: `${this.$t("home.barcodeScanner.emptySerialNumberMessage")}`,
          type: 'error',
          duration: 5000
        })
        return
      }
      if (await this.$store.dispatch('checkAsp')) return
      this.$store.dispatch('setLoading', true)

      const params = {
        id: this.serialNumber,
        deviceTemplate: this.deviceTemplate
      }

      const idField = this.deviceTemplate.activeVersion.parametersSchema.find(field => {return field.barcodeScanner})
      const device = {
        parameters: { [idField.name]: this.serialNumber},
        deviceTemplate: this.deviceTemplate
      }

      try {
        const result = await this.$store.dispatch('getDevice', params)
        this.$store.dispatch('setLoading', false)
        console.log(result)
        if (result.status === 'not-found') {
          this.modalText = `${this.$t("device.notFoundPart1")}  ${this.deviceTemplate.name} ${this.$t("device.notFoundPart2")}`
          this.$store.dispatch('saveDevice', device)
          this.modalAction = "new-device"
          this.$nextTick(() => { this.$refs.modal.show() })
        } else if (this.device.installation) {
          this.modalText = `${this.$t("device.foundInstalled")}`
          this.modalAction = "mounted-device"
          this.$nextTick(() => { this.$refs.modal.show() })
          console.log('device', this.device)
        }
        else {
          this.modalText = `${this.$t("device.foundNotInstalled")}`
          this.modalAction = "found-device"
          this.$nextTick(() => { this.$refs.modal.show() })
        }
      } catch (err) {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('saveDevice', device)
        this.modalText = `${this.$t("device.connectionError")}`
        this.modalAction = "offline"
        this.$nextTick(() => { this.$refs.modal.show() })
      }
    },
    startInstallation () {
      this.$router.push({
        path: `/installations/new/add-device/${this.device.id}`,
        query: {
          offline: this.modalAction === 'offline' ? true : null,
        }
      })
    },
    async goToInspection () {
      this.$refs.modal.close()
      this.$router.push({path: this.device.installation, hash: '#inspections' })
    },
    async goToService () {
      this.$refs.modal.close()
      this.$nextTick(() => {
        this.$store.dispatch('setLoading', true)
      })
      const result = await api.get(`/services?status=spare_parts_sent&customDevice.id=${this.device.id}`)
      const pendingServices = result.data
      console.log(pendingServices)
      this.$nextTick(() => this.$store.dispatch('setLoading', false))
      if (pendingServices.length) {
        this.serviceId = pendingServices[0].id
        this.$nextTick(() => { this.$refs.serviceModal.show() })
      } else {
        this.$router.push({
          path: `/services/new/device/${this.device.id}`
        })
      }
    },
    inProgress () {
      alert(`${this.$t("inProgress")}`)
    }
  },
};
</script>
