import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from '../store'
// import { Auth } from "aws-amplify";
import { api } from "@/assets/js/api-client.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {requiredRole: ['installer', 'ASP']}
  },
  /*{
    path: "/incognito",
    name: "incognito",
    component: () =>
      import(/* webpackChunkName: "incognito" "../views/public/Incognito.vue"),
    meta: {requiredRole: false}
  },*/
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/public/Login.vue"),
    meta: {requiredRole: false}
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/public/Register.vue"),
    meta: {requiredRole: false}
  },
  {
    path: "/edit-profile",
    name: "edit-profile",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/EditProfile.vue"),
    meta: {requiredRole: ['installer','admin','superadmin','ASP']}
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: () =>
      import(/* webpackChunkName: "passwordRecovery" */ "../views/public/PasswordRecovery.vue"),
    meta: {requiredRole: false}
  },
  {
    path: "/production",
    name: "production",
    component: () =>
      import(/* webpackChunkName: "production" */ "../views/production/Production.vue"),
    meta: {requiredRole: ['production', 'superadmin']}
  },
  {
    path: "/production/:id",
    name: "production-step",
    component: () =>
      import(/* webpackChunkName: "productionStep" */ "../views/production/ProductionStep.vue"),
    meta: {requiredRole: ['production', 'superadmin']}
  },
  {
    path: "/installations",
    name: "installations",
    component: () =>
      import(/* webpackChunkName: "installations" */ "../views/installations/Installations.vue"),
    meta: {requiredRole: ['installer', 'ASP']}
  },
  {
    path: "/inspections",
    name: "inspections",
    component: () =>
      import(/* webpackChunkName: "inspections" */ "../views/inspections/AllInspections.vue"),
    meta: {requiredRole: ['installer', 'ASP']}
  },
  {
    path: "/inspections/:id",
    name: "inspection",
    component: () =>
      import(/* webpackChunkName: "inspection" */ "../views/inspections/SingleInspection.vue"),
    meta: {requiredRole: ['installer', 'ASP']}
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(/* webpackChunkName: "inspection" */ "../views/services/AllServices.vue"),
    meta: {requiredRole: ['installer', 'ASP']}
  },
  {
    path: "/services/:id",
    name: "service",
    alias: "/services/:id/device/:deviceId",
    component: () =>
      import(/* webpackChunkName: "inspection" */ "../views/services/SingleService.vue"),
    meta: {requiredRole: ['installer', 'ASP']}
  },
  {
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/support/Support.vue"),
    meta: {requiredRole: ['installer', 'ASP']}
  },
  {
    path: "/support/documents",
    name: "documents",
    component: () =>
      import(/* webpackChunkName: "documents" */ "../views/support/Documents.vue"),
    meta: {requiredRole: ['installer', 'ASP']}
  },
  {
    path: "/installations/:id",
    name: "installation",
    component: () =>
      import(/* webpackChunkName: "installation" */ "../views/installations/SingleInstallation.vue"),
    meta: {requiredRole: ['installer', 'ASP']}
  },
  {
    path: "/installations/:id/add-device/:deviceId",
    name: "add-device",
    component: () =>
      import(/* webpackChunkName: "addDevice" */ "../views/installations/AddDevice.vue"),
    meta: { requiredRole: false }
  },
  {
    path: "/add-device-simple/:deviceId",
    name: "add-device-simple",
    component: () =>
      import(/* webpackChunkName: "addDevice" */ "../views/installations/AddDeviceSimple.vue"),
    meta: { requiredRole: ['installer', 'ASP'] }
  },
  {
    path: "/admin/devices",
    name: "admin-devices",
    component: () =>
      import(/* webpackChunkName: "adminDevices" */ "../views/admin/AdminDevices.vue"),
    meta: {requiredRole: ['admin','superadmin']}
  },
  {
    path: "/admin/devices/:id",
    name: "admin-device-details",
    component: () =>
      import(/* webpackChunkName: "adminDevices" */ "../views/admin/AdminDeviceDetails.vue"),
    meta: {requiredRole: ['admin','superadmin', 'ASP']}
  },
  {
    path: "/admin/services",
    name: "admin-services",
    component: () =>
      import(/* webpackChunkName: "adminDevices" */ "../views/admin/AdminServices.vue"),
    meta: {requiredRole: ['admin','superadmin', 'ASP']}
  },
  {
    path: "/admin/installations",
    name: "admin-installations",
    component: () =>
      import(/* webpackChunkName: "adminInstallations" */ "../views/admin/AdminInstallations.vue"),
    meta: {requiredRole: ['admin','superadmin', 'ASP']}
  },
  {
    path: "/admin/settings",
    name: "admin-settings",
    component: () =>
      import(/* webpackChunkName: "adminSettings" */ "../views/admin/AdminSettings.vue"),
    meta: {requiredRole: ['admin','superadmin']}
  },
  {
    path: "/admin/users",
    name: "admin-users",
    component: () =>
      import(/* webpackChunkName: "adminUsers" */ "../views/admin/AdminUsers.vue"),
    meta: {requiredRole: ['admin','superadmin']}
  },
  {
    path: "/admin/device-templates/:deviceTemplateId",
    name: "admin-device-template",
    component: () =>
      import(/* webpackChunkName: "adminDeviceTemplate" */ "../views/admin/AdminEditDeviceTemplate.vue"),
    meta: {requiredRole: ['admin','superadmin']}
  },
  {
    path: "/admin/device-templates/:deviceTemplateId/form-templates/:formTemplateId",
    name: "admin-form-template",
    component: () =>
      import(/* webpackChunkName: "adminFormTemplate" */ "../views/admin/AdminEditFormTemplate.vue"),
    meta: {requiredRole: ['admin','superadmin']}
  },
  {
    path: "/admin/device-templates/:deviceTemplateId/warranty-template",
    name: "admin-warranty-template",
    component: () =>
      import(/* webpackChunkName: "adminFormTemplate" */ "../views/admin/AdminEditWarrantyTemplate.vue"),
    meta: {requiredRole: ['admin','superadmin']}
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 80
      }
    }
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiredRole) {
    const now = Math.floor(Date.now() / 1000)
    if (store.state.role && (now < store.state.exp)) {
      if (store.state.user.organisation.id != process.env.VUE_APP_ORG_ID) next({name: 'login', query: {nouser: true}})
      else if (to.meta.requiredRole.includes(store.state.role.name)) {
        next()
      }
      else {
        next({name: store.state.role.redirectTo})
      }
    }
    else {
      store.dispatch('setLoading', true)
      try {
        await api.post("/token/refresh", {});
        await handleAuthData(to, next)
      } catch (err) {
        console.log(err)
        try{
          await api.get('/token/invalidate')
          store.dispatch('clearUser');
          store.dispatch('setLoading', false)
          next({name: 'login'})
        } catch (err) {
          console.log(err)
          store.dispatch('setLoading', false)
          next({name: 'login'})
        }
      }
    }
  }
  else next()
})

async function handleAuthData(to, next) {
  const result = await api.get('/user')
  const { user, exp } = result.data
  if (user.organisation.id != process.env.VUE_APP_ORG_ID) {
    store.dispatch('setLoading', false)
    next({name: 'login', query: {nouser: true}})
  }
  else {
    let role
    if (user.roles.includes('ROLE_SUPERADMIN')) role = {name: "superadmin", redirectTo: "admin-installations"}
    else if (user.roles.includes('ROLE_ADMIN')) role = {name: "admin", redirectTo: "admin-installations"}
    else if (user.roles.includes('ROLE_PRODUCTION')) role = {name: "production", redirectTo: "production"};
    else if (user.roles.includes('ROLE_ASP')) role = {name: "ASP", redirectTo: "home"};
    else role = {name: "installer", redirectTo: "home"}
    store.dispatch('saveUser', user)
    store.dispatch('saveRole', role)
    store.dispatch('saveExp', exp)
    store.dispatch('setLoading', false)
    if (to.meta.requiredRole.includes(store.state.role.name)) next()
    else next({name: store.state.role.redirectTo})
  }
}

export default router;
