<template>
    <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
        <div class="grid grid-cols-2 gap-4 sm:grid-cols-2">
            <div v-for="item in menuItems" :key="item.to" class="group relative rounded-lg border border-gray-300 bg-white px-4 py-4 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <div class="group-hover:text-ifgreen flex-shrink-0 text-ifgray">
                  <component :is="item.icon" class="h-10 w-10" />
                </div>
                <div class="flex-1 min-w-0">
                  <router-link :to="item.to" class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-sm font-medium text-gray-900">{{ item.label }}</p>
                  </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ClockIcon, CogIcon, HomeIcon, LifebuoyIcon } from "@heroicons/vue/24/outline";

    export default {
      components: {
          ClockIcon,
          CogIcon,
          HomeIcon,
          LifebuoyIcon
      },
      computed: {
        menuItems () {
          return [
            {to: '/installations', label: `${this.$t("home.installations")}`, icon: HomeIcon },
            {to: '/inspections', label: `${this.$t("home.inspections")}`, icon: ClockIcon },
            {to: '/services', label: `${this.$t("home.services")}`, icon: CogIcon },
            {to: '/support', label: `${this.$t("home.support")}`, icon: LifebuoyIcon }
          ]
        }
      }
    }
</script>
