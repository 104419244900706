<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton :class="btnClass + ' inline-flex w-full  gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-inset ring-gray-300 hover:bg-gray-50 uppercase'">
        {{$i18n.locale}}
        <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      
      <MenuItems :class="dropdownClass + ' absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'">
        <div class="py-1">
          <MenuItem v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale" v-slot="{ active }">
            <a 
              href="#"
              @click="setLocale(locale)"
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm uppercase']">
              <img :src="`/img/icons/flag_${locale}.png`" :alt="locale" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              {{locale}}
            </a>
          </MenuItem>

        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon
  },
  props: {
    btnClass: String,
    dropdownClass : String
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('lang',locale)
    }
  }
}

</script>