const warrantyText = (orgId, heatpump) => {
  if (orgId == 1) {
    let deviceNames, lastListPoint, separatePumpNote
    if (heatpump === 'Izzifast R290' || heatpump === 'Pro') {
      deviceNames = 'Szafa hydrauliczna i pompa ciepła'
      lastListPoint = 'szkód powstałych w wyniku zamarznięcia wymiennika pompy ciepła.'
      separatePumpNote = null
    } else {
      deviceNames = 'Szafy hydrauliczne'
      lastListPoint = null
      separatePumpNote = 'Jednostka zewnętrzna pompy ciepła objęta jest gwarancją producenta na osobnym druku gwarancyjnym'
    }

    return {
      ol: [
        [
          'Firma Izzifast Sp. z o.o. zapewnia sprawne funkcjonowanie sprzedawanych urządzeń pod warunkiem, że:',
          {
            margin: [10, 10, 10, 10],
            ul: [
            'są instalowane przez przeszkolonych instalatorów posiadających niezbędną wiedzę oraz uprawnienia do instalacji pomp ciepła,',
            'są instalowane zgodnie z obowiązującymi w Polsce przepisami i normami oraz wytycznymi producenta zawartymi w Instrukcji Obsługi jednostki wewnętrznej oraz zewnętrznej.',
            'są użytkowane zgodnie z przeznaczeniem, zasadami użytkowania i konserwacji zawartymi w Instrukcji Obsługi',
            ]
          }
        ],
        { text: `${deviceNames} objęte są gwarancją prawidłowego działania w okresie 60 miesięcy licząc od daty „pierwszego uruchomienia”, jednak nie dłużej niż 6 miesięcy od daty sprzedaży przez Przedstawiciela Izzifast`,
          margin: [0, 0, 10, 10]
        },
        [
          'Warunkiem obowiązywania gwarancji jest:',
          {
            margin: [10, 10, 10, 10],
            ul: [
            'posiadanie dowodu zakupu i ważnej Karty Gwarancyjnej wypełnionej przez osoby do tego uprawnionej lub rejestracja urządzenia w systemie app.izzifast lub inprax',
            'wykonanie przez Autoryzowanego Serwisanta przeglądu gwarancyjnego każdego roku eksploatacji i wpis w dzienniku obsługi w Karcie Gwarancyjnej lub systemie app.izzifast lub inprax.',
            ]
          }
        ],
        [
          'Karta Gwarancyjna jest ważna, jeżeli:',
          {
            margin: [10, 10, 10, 10],
            ul: [
            '"pierwszego uruchomienia" dokonał Autoryzowany Serwisant / Instalator, ',
            'jest podpisana przez Użytkownika urządzenia podlegającego gwarancji lub urządzenie zarejestrowane jest w systemie app.izzifast lub inprax',
            'nie znajdują się na niej żadne ślady zmian, poprawek, skreśleń itp',
            'dołączono do niej dowód zakupu.',
          ]
          }
        ],
        { text: 'Naprawa urządzenia wykonywana będzie u Użytkownika. Przez naprawę rozumie się wykonywanie czynności o charakterze specjalistycznym, właściwym dla usunięcia wady bądź usterki w zakresie objętym gwarancją. Gwarant może odmówić wykonania naprawy w przypadku braku swobodnego dostępu do urządzenia.',
          margin: [0, 0, 0, 10]
        },
        { text: 'Naprawy może dokonać instalator montujący urządzenie w porozumieniu z Gwarantem.',
          margin: [0, 0, 0, 10]
        },

        { text: 'W ramach gwarancji zostaną bezpłatnie usunięte wszystkie wady jednoznacznie wynikające z winy producenta. Wymienione uszkodzone części stanowią własność Gwaranta.',
          margin: [0, 0, 0, 10]
        },
        { text: 'Szybkość reakcji na zgłoszenie usterki wynosi 48h w dni robocze. Zgłoszeń należy dokonywać mailowo szafy@izzifast.pl, lub telefonicznie nr 531 460 516',
          margin: [0, 0, 0, 10]
        },
        { text: 'Wady lub usterki ujawnione w okresie gwarancji będą usuwane niezwłocznie, lecz nie dłużej niż w ciągu 14 dni roboczych od daty zgłoszenia reklamacji. Okres ten może ulec wydłużeniu o czas sprowadzenia części zamiennych od Producenta.',
        margin: [0, 0, 0, 10]
        },
        [
          'Gwarancja nie obejmuje wad i uszkodzeń powstałych w wyniku:',
          {
            margin: [10, 10, 10, 10],
            ul: [
            'wadliwego wykonania instalacji,',
            'wykonania „pierwszego uruchomienia” przez Użytkownika lub osoby nieuprawnione,',
            'postępowania niezgodnego z zaleceniami instrukcji obsługi, użytkowania, transportu, składowania i konserwacji,',
            'uszkodzeń mechanicznych i ich następstw,',
            'pożaru, zalania wodą, uderzenia pioruna, przepięć w sieci energetycznej, nieprawidłowego napięcia zasilającego, czy też wpływu innych czynników zewnętrznych lub wynikających z działania sił przyrody. Gwarancja nie obejmuje elementów urządzeń, które ulegają naturalnemu zużyciu lub w prawidłowy sposób spełniły swoją funkcję zabezpieczającą np. diody, uszczelki, bezpieczniki, anody ochronne zasobników c.w.u. itp., ',
            'wykonywania napraw i ingerencji w konstrukcję urządzenia przez osoby nieupoważnione,',
            lastListPoint
            ]
          }
        ],
        { text: 'Gwarancja traci ważność, jeżeli w urządzeniu zostanie dokonana naprawa lub inna ingerencja w konstrukcję urządzenia przez osobę nieuprawnioną albo z wykorzystaniem nieoryginalnych części zamiennych, bez uprzedniego uzgodnienia tego z Gwarantem.',
          margin: [0, 0, 0, 10]
        },
        { text: 'Naczynia przeponowe, pompka cyrkulacyjna CWU objęte są 24 miesięczną gwarancją.',
          margin: [0, 0, 0, 10]
        },
        { text: 'Elementy układu CWU nie podlegają gwarancji w przypadku braku zamontowanego reduktora ciśnienia wody.',
          margin: [0, 0, 0, 10]
        },
        { text: 'Producent może odmówić naprawy gwarancyjnej produktu, jeśli uszkodzenia będące wynikiem korozji zostały wywołane przez agresywną wodę - na podstawie Rozporządzenia Ministra Zdrowia z dnia 29.03.2007 r. w sprawie jakości wody przeznaczonej do spożycia przez ludzi (Dz. U. Nr 61 poz. 417 ze zm.) - lub z powodu zbyt słabej przewodności wody (co najmniej 150 μS/cm-1)',
          margin: [0, 0, 0, 10]
        },
        { text: 'Niniejsza gwarancja na sprzedany towar konsumpcyjny nie wyłącza, nie ogranicza, ani nie zawiesza uprawnień kupującego wynikających z przepisów o rękojmi za wady rzeczy sprzedanej.',
          margin: [0, 0, 0, 10]
        },
        { text: 'W sprawach nieuregulowanych warunkami niniejszej gwarancji zastosowanie mają odpowiednie przepisy Kodeksu Cywilnego.',
          margin: [0, 0, 0, 10]
        },
        { text: 'Gwarant nie odpowiada za straty i szkody powstałe w wyniku braku możliwości korzystania z wadliwie działającego lub uszkodzonego urządzenia. ',
          margin: [0, 0, 0, 10]
        },
        { text: 'Gwarancja nie obejmuje czynności serwisowych, kontrolnych, pomiarowych i regulacji układu, dokonywanych na sprawnym urządzeniu bez związku z jego awarią (takie czynności mogą być dodatkową usługą, płatną zgodnie z obowiązującymi cennikami).',
          margin: [0, 0, 0, 10]
        },
        { text: 'Niniejsza gwarancja udzielana jest na urządzenia zakupione i zainstalowane na terenie Rzeczpospolitej Polskiej',
          margin: [0, 0, 0, 10]
        },
        { text: separatePumpNote,
          margin: [0, 0, 0, 10]
        },
      ],
      margin: [0, 20, 0, 0]
    }
  } else if (orgId == 2) {
    return {
      style: 'medium',
      ol: [
        {
          text: `Niniejsza gwarancja dotyczy wszystkich urządzeń marki RICOM HEAT, zakupionych i sprzedawanych na terenie Rzeczypospolitej Polskiej, dystrybuowanych przez firmę RICOM ENERGY sp. z o.o. (zwaną dalej Dystrybutorem) z siedzibą w Skawinie 32-050, przy ul. płk. Andrzeja Hałacińskiego 2, wpisaną do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Krakowa-śródmieścia w Krakowie, XII Wydział Gospodarczy KRS pod numerem 0000539848, NIP: 6793107189, REGON: 360607810, tel. +48 517 735 332, e-mail: biuro@ricomenergy.pl`,
          margin: [0, 0, 10, 10]
        },
        { text: `Dla skorzystania z niniejszej gwarancji wymagane jest uruchomienie urządzenia przez Autoryzowany Serwis Ricom, w skrócie ASR (zwany równiez Gwarantem), posiadającym, aktualny Certyfikat ASR, zarejestrowaną działalność gospodarczą w branży chłodnictwo i klimatyzacja, aktualny certyfikat F-GAZ-owy i uprawnienia elektryczne SEP. ASR ma prawo odmówić wykonania uruchomienia urządzenia gdy instalacja hydrauliczna , chłodnicza , elektryczna odbiega od wytycznych zawartych w Instrukcji Obsługi Ricom Heat.`,
          margin: [0, 0, 10, 10]
        },
        [
          'Dystrybutor gwarantuje sprawne działanie urządzenia, na które udzielana jest gwarancja, według poniższych postanowień:',
          {
            margin: [0, 10, 0, 10],
            table: {
              widths: [240, 115, 115],
              // heights: [20, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,],
              body: [
                [
                  {text: 'Urządzenia RICOM HEAT', alignment: 'center', color: '#fff', bold: true},
                  {text: 'Okres podstawowej gwarancji', alignment: 'center', color: '#fff', bold: true},
                  {text: 'Okres rozszerzonej gwarancji', alignment: 'center', color: '#fff', bold: true}
                ],
                [
                  {text: 'Pompy ciepła: SPLIT, MONOBLOK, ALLINONE, ecoAIR+, Klimakonwektory: DRACO, Szafy hydrauliczne: HYDRA', alignment: 'center'},
                  {text: '27 miesięcy', alignment: 'center'},
                  {text: '60 miesięcy', alignment: 'center'}
                ]
              ]
            },
            layout: {
              paddingLeft: function () {return 5},
              paddingRight: function () {return 5},
              paddingTop: function () {return 5},
              paddingBottom: function () {return 5},
              fillColor: function (rowIndex) {
                return (rowIndex === 0) ? '#ef7923' : null
              },
            }
          },
          {
            margin: [0, 0, 0, 10],
            table: {
              widths: [240, 240],
              // heights: [20, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,],
              body: [
                [
                  {text: 'Warunki podstawowej gwarancji', alignment: 'center', color: '#fff', bold: true},
                  {text: 'Warunki rozszerzonej gwarancji', alignment: 'center', color: '#fff', bold: true},
                ],
                [
                  {
                    ul: [
                      `podpisanie protokołu odbioru / przekazania w ciągu 72 godzin od dostarczenia urządzenia do użytkownika, świadczący o nie uszkodzonym urządzeniu w trakcie transportu,`,
                      `odpłatny przegląd techniczny dokonywany co najmniej raz na 12 miesięcy przez ASR posiadający aktualny Certyfikat ASR,`,
                      `czytelnie i poprawnie wypełniona niniejsza Karta Gwarancyjna wraz z odnotowanymi przeglądami i ewentualnymi naprawami,`,
                      `przedstawienie dowodu zakupu urządzenia,`,
                      `użytkowanie urządzenia zgodne z przeznaczeniem i warunkami techniczno-eksploatacyjnymi zawartymi w instrukcji obsługi, w szczególności: właściwa eksploatacja, konserwacja, obsługa, przechowywanie itp.,`,
                      `stosowanie oryginalnych części zamiennych dedykowanych dla urządzenia dostępnych u dystrybutora,`,
                      `zastosowanie filtra cząstek stałych oraz zamontowanie odpowietrzenia instalacji w możliwie najwyższym punkcie.`
                    ]
                  },
                  {
                    ul: [
                      `na karcie gwarancyjnej udokumentowany odpłatny przegląd techniczny dokonywany co najmniej raz na 12 miesięcy przez ASR posiadający aktualny Certyfikat ASR. Koszt przeglądu wynosi min. 500 zł netto,`,
                      `czytelnie i poprawnie wypełniona niniejsza Karta Gwarancyjna wraz z odnotowanymi przeglądami i ewentualnymi naprawami,`,
                      `użytkowanie urządzenia zgodne z przeznaczeniem i warunkami techniczno-eksploatacyjnymi zawartymi w instrukcji obsługi, w szczegóIności właściwa eksploatacja, konserwacja, obsługa, przechowywanie itp.,`,
                      `stosowanie oryginalnych części zamiennych dedykowanych dla urządzenia dostępnych u dystrybutora,`,
                      `Koszt min. 500 zł netto plus koszty dojazdu.`
                    ]
                  },
                ],
              ]
            },
            layout: {
              paddingLeft: function () {return 5},
              paddingRight: function () {return 5},
              paddingTop: function () {return 5},
              paddingBottom: function () {return 5},
              fillColor: function (rowIndex) {
                return (rowIndex === 0) ? '#ef7923' : null
              }
            }
          }
        ],
        { text: `Okres gwarancji urzędzeń RICOM HEAT jest liczony od daty sprzedaży Kupującemu. Przez datę sprzedaży rozumie się datę wystawienia dowodu zakupu urządzenia (faktura/paragon). ASR wraz z Użytkownikiem samodzielnie i we własnym zakresie ustalają długość trwania gwarancji. lnformacja taka musi zostać odnotowana w niniejszej Karcie Gwarancyjnej przez ASR.`,
          margin: [0, 0, 0, 10]
        },
        { text: `W przypadku uzasadnionym warunkami eksploatacyjnymi, ASR ma prawo wyznaczyć dodatkowy odptatny przegląd techniczny. lnformacja taka musi zostać wpisana do Karty Gwarancyjnej przez ASR.`,
          margin: [0, 0, 0, 10]
        },
        { text: `Dla prawidłowej pracy urządzenia zalecane jest, aby ASR wykonywał odpłatne okresowe przeglądy techniczne, pierwszy przegląd techniczny jest po roku użytkowania urządzenia.`,
          margin: [0, 0, 0, 10]
        },
        { text: `ASR wykonujący instalację urządzenia jest zobowiązany do przeprowadzenia konfiguracji aplikacji mobilnej służącej do sterowania pompą ciepła przez połączenie internetowe (jeżeli takie istnieje) oraz do przeszkolenia Kupującego z obsługi pympy ciepła oraz sposobów jej sterowania.`,
          margin: [0, 0, 0, 10]
        },
        [
          'Gwarancja traci ważność, w przypadku:',
          {
            margin: [10, 10, 10, 10],
            ul: [
              'przeprowadzenia montażu, napraw lub przeglądów technicznych przez osoby nieuprawnione (nieposiadające Certyfikatu ASR, F-GAZ, SEP),',
              'braku okresowych przeglądów technicznych,',
              'dokonywa nia zmian konstrukcyjnych,',
              'użytkowania urządzenia niezgodnie z przeznaczeniem i warunkami techniczno-eksploatacyjnymi.',
            ]
          }
        ],
        [
          'Gwarancją objęte są wyłącznie uszkodzenia i wady ukryte w urządzeniu. Gwarancja nie obejmuje:',
          {
            margin: [10, 10, 10, 10],
            ul: [
              'uszkodzeń wynikających z błędów i zaniedbań popełnionych podczas montażu,',
              'czynności i zużytych części wynikających z eksploatacji urządzenia, tj. konserwacja i wymiana filtrów, wymiana baterii w pilocie itp.,',
              'wad i awarii wynikających z normalnego zużycia podczas eksploatacji urządzenia, tj. zarysowania, trudne do usunięcia zabrudzenia, wytarcia napisow, itp.,',
              'instalacji czynnika chłodniczego, systemu odprowadzania skroplin i zasilania elektrycznego,',
              'mechanicznych uszkodzeń i wynikających z nich wad,',
              `uszkodzeń powstałych z przyczyn zewnętrznych lub siły wyższej (np. wyładowań atmosferycznych, powodzi, pożaru, korozji, przepięć sieci elektrycznej,
              przerw w dostawie energii elektrycznej itp.).`,
            ]
          }
        ],
        { text: `Niniejsza gwarancja uprawnia Kupującego do diagnostyki i naprawy urządzenia przez Dystrybutora. ASR, po otrzymaniu zgłoszenia, we własnym zakresie diagnozuje potencjalną awarię, przeprowadza demontaż uszkodzonych i montaż nowych elementów. Dystrybutor zapewnia bezpłatnie nowe elementy podlegające wymianie. Części, które zostały wymienione podczas naprawy gwarancyjnej urządzenia, stają się własnością Dystrybutora i muszą zostać dostarczone przez ASR do Dystrybutora w terminie 14 dni roboczych od dnia przeprowadzenia naprawy sprzętu, ale nie później niż 30 dni od dostarczenia części przez Dystrybutora.`,
          margin: [0, 0, 0, 10]
        },
        { text: `W przypadku, gdy podmiotem Sprzedającym, będzie inny podmiot niż podmiot montujący urządzenie, Dystrybutor przejmuje na siebie zobowiązania określone w pkt. 10 niniejszej Karty Gwarancyjnej.`,
          margin: [0, 0, 0, 10]
        },
        { text: `Dystrybutor nie ponosi odpowiedzialności za straty finansowe lub inne następstwa cywilnoprawne wynikające z wystąpienia wady w reklamowanym urządzeniu.`,
          margin: [0, 0, 0, 10]
        },
        { text: `Termin rozpatrzenia gwarancji wynosi 14 dni roboczych od momentu zgłoszenia reklamacji. W przypadku konieczności dostawy części zamiennych od producenta urządzenia termin może się wydłużyć do 28 dni roboczych.`,
          margin: [0, 0, 0, 10]
        },
        { text: `Kupujący urządzenie ponosi koszty związane z naprawą gwarancyjną w przypadku nieuzasadnionego zgłoszenia reklamacyjnego. Koszt bezpodstawnego wezwania serwisu wynosi 500 zł netto, koszt regulacji sterownika/parametrów użytkowych zmienionych przez użytkownika bez przyczyny: 200 zł netto.`,
          margin: [0, 0, 0, 10]
        },
        { text: `Gwarancja nie wyłącza, nie ogranicza ani nie zawiesza uprawnień kupującego wynikających z przepisów o rękojmi za wady rzeczy sprzedanej.`,
          margin: [0, 0, 0, 10]
        },
        { text: `Reklamację (zgłoszenie gwarancyjne) Kupujący zgłasza każdorazowo podmiotowi Sprzedającemu urządzenie i Autoryzowanemu Serwisantowi i/lub Autoryzowanemu Instalatorowi RICOM. Dane kontaktowe Dystrybutora znajdują się w niniejszej Karcie Gwarancyjnej. Zgłoszenie gwarancyjne musi zostać dostarczone w formie pisemnej na adres Dystrybutora lub za pośrednictwem drogi elektronicznej na adres e-mail: serwis@ricomenergy.com.`,
          margin: [0, 0, 0, 10]
        },
        { text: `Wszelkie widoczne uszkodzenia przesyłki/palety należy zgłosić w obecności kuriera i spisać protokół uszkodzenia.`,
          margin: [0, 0, 0, 10]
        }
      ],
      margin: [0, 20, 0, 0]
    }
  }
}

export default warrantyText
