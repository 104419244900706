<template>
  <li>
    <div @click="close" class="px-4 py-4 sm:px-6 relative" :class="uploadProgress == 100 ? 'cursor-pointer' : ''">
      <div :style="`width: ${uploadProgress}%`" class="absolute transition-all duration-100 top-0 left-0 h-full" :class="error ? 'bg-red-50' : 'bg-green-50'"></div>
      <div class="flex justify-between relative">
        <p class="truncate text-sm font-medium text-ifgreen">{{ file.name }}</p>
        <p class="text-sm">{{ progressPercent }}</p>
      </div>
      <div class="mt-1 sm:flex sm:justify-between relative">
        <p class="sm:inline-flex text-xs font-semibold leading-5 text-gray-800">{{ file.type }}</p>
        <p class="text-xs inline-flex items-center">
          {{ status }}
          <CogIcon v-if="uploadProgress < 100" class="inline animate-spin w-4 h-4 ml-1" />
          <XCircleIcon v-else-if="error" class="inline w-4 h-4 ml-1" />
          <CheckCircleIcon v-else class="inline w-4 h-4 ml-1" />
        </p>
      </div>
    </div>
  </li>
</template>
<script>

import axios from 'axios'
import { uuid } from 'vue3-uuid'
import { api } from "@/assets/js/api-client.js"
import { CogIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    CogIcon,
    CheckCircleIcon,
    XCircleIcon
  },
  emits: ['close', 'success', 'error'],
  props: {
    organisation: Number,
    language: String,
    file: Object,
    mode: {
      type: String,
      default: 'file-library'
    }
  },
  data() {
    return {
      uploadProgress: 0,
      status: `${this.$t("settings.starting")}...`,
      error: false,
      path: null
    }
  },
  computed: {
    progressPercent() {
      return this.error ? this.$t("home.barcodeScanner.errorTitle") : Number(this.uploadProgress/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
    },
    headers() {
      return {
        'Content-Type': this.file.type,
        'x-amz-acl': 'public-read'
      }
    }
  },
  methods: {
    async uploadFile() {
      this.uploadProgress = 10
      this.error = false
      
      try {
        const signedUrl = await this.getSignedUrl()
        try {
          await this.putToS3(signedUrl)
          try {
            await this.saveToDb()
            this.status = `${this.$t("settings.fileUploadSuccess1")} ${this.mode == 'file-library' ? this.$t("settings.fileUploadSuccess2") : ''}`
          } catch (error) {
            this.error = true
            this.uploadProgress = 100
            this.status = `${this.$t("settings.errorSavingLink")}`
            this.$emit('error')
          }      
        } catch (error) {
          this.error = true
          this.uploadProgress = 100
          this.status = `${this.$t("settings.errorSavingFile")}`
          this.$emit('error')
          console.log(error)
        }
      } catch (error) {
        this.error = true
        this.uploadProgress = 100
        this.status = `${this.$t("settings.errorGettingKey")}`
        this.$emit('error')
        console.log(error)
      }
    },
    onUploadProgress (progressEvent) {
      const { loaded, total } = progressEvent;
      this.uploadProgress = 10 + Math.floor((loaded * 80) / total);
    },
    async getSignedUrl() {
      this.status = `${this.$t("settings.getSignedUrl")}...`
      
      this.path = this.mode == 'file-library' ? `${uuid.v4()}.${this.file.name.split('.').at(-1)}` : this.file.name

      const fileData = [{
        filename: this.path,
        filetype: this.file.type
      }]

      const result = await api.post('/get-s3-urls', fileData)
      return result.data.urls[0]
    },
    async putToS3 (url) {
      this.status = `${this.$t("settings.sendingFile")}...`
      const { headers, onUploadProgress } = this
      const result = axios.put(url, this.file, {headers, onUploadProgress})
      return result
    },
    async saveToDb () {
      let result = {data: this.file.name}
      if (this.mode == 'file-library') {
        this.status = `${this.$t("settings.savingReference")}...`
        const data = {
          organisation: `/organisations/${this.organisation}`,
          name: this.file.name,
          type: this.file.type,
          path: this.path,
          language: this.language
        }
        result = await api.post('/files', data)
      }
      this.uploadProgress = 100
      this.$emit('success', result.data)
    },
    close() {
      if (this.uploadProgress == 100) {
        if (this.error) this.uploadFile()
        else this.$emit('close')
      }
    }
  },
  mounted() {
    this.uploadFile()
  },
  watch: {
    file: {
      deep: true,
      handler() {
        console.log(this.file, "UA!")
      }
    }
  }
};

</script>