<template>
  <Listbox v-if="selected" as="div" v-model="selected">
    <div class="relative">
      <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen sm:text-sm">
        <span class="block truncate">{{ selected.name }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <img :src="selected.icon" class="h-5 w-5" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="option in options" :key="option.id" :value="option" v-slot="{ active, selected }">
            <li :class="[active ? 'text-white bg-ifgreen' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ option.name }}
              </span>

              <span v-if="selected" :class="[active ? 'text-white' : 'text-ifgreen', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/solid'
import { mapState } from 'vuex'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon
  },
  emits: ['change'],
  props: {
    default: Number,
    global: {
      type: Boolean,
      default: true
    }
  },
  async created() {
    if (!this.organisations) await this.$store.dispatch('getOrganisations')
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState(['organisation', 'organisations']),
    options () {
      return this.organisations ? this.organisations.map(org => {
        return {...org, icon: `/img/icons/${org.id}/favicon.ico`}
      }) : []
    }
  },
  watch: {
    options: {
      immediate: true,
      handler () {
        console.log(this.options)
        this.selected = this.options.find(org => {
          const orgId = this.default || this.organisation
          return org.id == orgId
        })
      }
    },
    selected () {
      if (this.global) {
        this.$store.dispatch('saveOrganisation', this.selected.id)
        console.log(this.organisation)
      } else {
        this.$emit('change', this.selected.id)
      }
    }
  }
}
</script>
