<template>
  <div class="relative">
    <label v-if="label" :for="id" class="block mb-1 text-sm font-medium text-gray-700">{{ label }}</label>
    <select :id="id" :name="id" :value="modelValue" @change="handleInput" :class="`block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm ${icon ? 'pl-8' : 'pl-3'} ${roundedRight ? 'rounded-md' : 'rounded-l-md'}`">
      <option value="" selected>{{ placeholder ? placeholder : $t('select') }}</option>
      <template v-if="grouped">
        <optgroup v-for="group in options" :key="group.name" :label="group.name">
          <option v-for="option in group.value" :key="option.value" :value="option.value">{{ option.name }}</option>
        </optgroup>
      </template>
      <template v-else>
        <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}</option>
      </template>
    </select>
    <MagnifyingGlassIcon v-if="icon" class="h-5 w-5 text-gray-400 mr-1 absolute left-2 bottom-2.5" aria-hidden="true" />
  </div>
</template>

<script>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'


export default {
  name: "NativeSelect",
  emits: ['update:modelValue'],
  components: {
    MagnifyingGlassIcon
  },
  props: {
    modelValue: String,
    options: Array,
    label: String,
    placeholder: {
      type: String
    },
    id: String,
    grouped: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    roundedRight: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('update:modelValue', e.target.value)
    }
  }
};
</script>
