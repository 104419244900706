<template>
<svg class="mx-auto h-8 w-auto" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 260 1920 560" xml:space="preserve">
<g>
<path class="st0" d="M141.71,265.03c8.77,0.89,17.54,1.78,26.31,2.66c9.72,0.98,19.43,1.95,29.15,2.91
c7.74,0.76,15.49,1.49,23.23,2.25c8.17,0.8,16.34,1.63,24.51,2.43c7.57,0.75,15.14,1.47,22.71,2.21
c8.26,0.81,16.51,1.65,24.77,2.45c7.83,0.76,15.66,1.5,23.49,2.26c8,0.78,16,1.6,23.99,2.39c5.94,0.59,11.87,1.15,17.81,1.73
c8.17,0.8,16.34,1.63,24.51,2.43c7.74,0.76,15.49,1.49,23.23,2.25c8.09,0.79,16.17,1.61,24.25,2.4c7.83,0.77,15.66,1.51,23.49,2.28
c8.08,0.79,16.17,1.61,24.25,2.41c7.66,0.75,15.31,1.49,22.97,2.24c8.17,0.8,16.34,1.63,24.51,2.43
c7.66,0.75,15.31,1.48,22.97,2.24c8.26,0.81,16.51,1.64,24.77,2.45c7.74,0.76,15.49,1.49,23.23,2.25
c8.09,0.79,16.17,1.61,24.25,2.41c5.76,0.57,11.52,1.25,17.29,1.68c24.18,1.8,41.76,13.83,53.42,34.72
c7.48,13.41,10.41,27.97,9.31,43.25c-0.67,9.31-1.85,18.57-2.79,27.86c-0.76,7.57-1.49,15.14-2.25,22.71
c-0.8,8-1.63,15.99-2.43,23.99c-0.76,7.57-1.49,15.14-2.25,22.71c-0.8,8-1.63,15.99-2.43,23.99c-0.76,7.57-1.49,15.14-2.25,22.71
c-0.79,7.91-1.61,15.82-2.41,23.73c-0.76,7.57-1.49,15.14-2.25,22.71c-0.8,8-1.63,15.99-2.43,23.99
c-0.76,7.57-1.49,15.14-2.25,22.71c-0.8,8-1.63,15.99-2.43,23.99c-0.76,7.57-1.49,15.14-2.25,22.71c-0.8,8-1.61,15.99-2.43,23.99
c-0.53,5.16-0.96,10.33-1.64,15.47c-1.55,11.86-6.27,22.44-13.57,31.83c-10.38,13.36-23.71,22.29-40.3,26.16
c-4.14,0.97-8.36,1.31-12.57,1.78c-9.36,1.04-18.73,2.02-28.1,3.01c-7.22,0.76-14.44,1.49-21.66,2.26
c-8.94,0.95-17.87,1.92-26.81,2.88c-7.22,0.77-14.44,1.52-21.66,2.29c-9.02,0.96-18.05,1.93-27.07,2.9
c-7.22,0.77-14.44,1.53-21.66,2.3c-7.39,0.79-14.78,1.6-22.17,2.38c-7.22,0.77-14.44,1.52-21.66,2.29
c-9.02,0.96-18.04,1.94-27.07,2.9c-7.22,0.77-14.44,1.52-21.66,2.29c-7.39,0.79-14.78,1.6-22.17,2.39
c-7.13,0.76-14.27,1.5-21.4,2.26c-9.2,0.98-18.39,1.98-27.58,2.96c-7.05,0.75-14.1,1.48-21.14,2.23
c-9.19,0.98-18.39,1.98-27.58,2.96c-7.22,0.77-14.44,1.5-21.66,2.27c-7.99,0.86-15.98,1.75-23.97,2.62
c-0.62,0.39-1.31,0.15-1.97,0.22c-0.9,0.12-1.85-0.28-2.71,0.27c-3.98,0-7.96,0-11.94,0c-1.04-0.56-2.16-0.13-3.24-0.27
c-0.72,0-1.43,0-2.15,0c-25.11-2.57-44.6-14.27-57.95-35.8c-3.91-6.31-6.36-13.3-7.49-20.62c-2.59-16.84-4.5-33.77-6.72-50.66
c-5.74-43.73-11.34-87.47-17.03-131.2c-4.69-36.1-9.35-72.2-14.01-108.31c-4.83-37.39-9.67-74.77-14.52-112.16
c-0.46-3.51-1.08-7.01-0.96-10.57c-0.01-1.99,0.09-4-0.52-5.94c0-2.77,0-5.54,0-8.31c0.54-1.67,0.52-3.39,0.52-5.11
c1.05-10.88,4.69-20.87,10.53-30.04c11.78-18.5,28.7-29.48,50.05-33.89c2.8-0.58,5.64-0.67,8.49-0.77c0.79,0,1.59,0,2.38,0
c0.75-0.11,1.56,0.25,2.27-0.27c2.25,0,4.5,0,6.75,0c1.04,0.56,2.16,0.13,3.24,0.27C140.05,264.9,140.91,264.62,141.71,265.03z"/>
<path class="st1" d="M66.27,349.14c-4.72-34.73,16.43-64.53,49.21-72.24c9.65-2.27,19.34-2.33,29.12-1.26l493.44,48.52
c16.97,1.41,30.15,9.44,39.97,23.08c8.8,12.22,12.55,26.04,12.03,41.04L657.79,711.6c-1.32,12.66-6.58,23.55-15.07,32.91
c-9.37,10.33-20.88,16.97-34.73,19.18l-422.12,44.75c-17.61-0.25-33.02-4.82-46.28-15.5c-10.46-8.42-17.43-19.15-19.95-32.45
L66.27,349.14z"/>
<g>
<path class="st2" d="M671.23,378.83c-0.89-5.63-2.41-11.03-5.19-15.97c-37.17,41.91-89.8,79.45-153.81,110.15
c41.88,35.9,86.19,73.9,87.64,75.2c0.94,0.84,1.7,0.93,2.74,0.27c1.17-0.73,2.4-1.38,3.66-1.94c1.12-0.49,1.45-1.19,1.39-2.41
c-0.34-6.39-0.58-12.78-0.84-19.17c-0.4-9.93-4.64-102.78-3.7-104.02c2.62,4.77,38.52,69.43,53.87,96.98
c0.35,0.63,0.53,1.44,1.53,1.8c0.25-2.36,11.67-116.27,12.47-124.09C671.58,390.04,672.12,384.45,671.23,378.83z"/>
<path class="st2" d="M482.34,690.3L310.71,538.29c-49.35,9.75-101.49,16.15-155.53,18.65c-14.57,0.67-29.02,1.05-43.34,1.14
l25.16,194.3c1.1,10.53,5.56,19.2,13.73,25.84c11.7,9.5,25.3,12.73,40.09,11.41c0,0,222.62-23.44,225.65-24.19
c-1.15-0.63-124.16-62.82-124.16-62.82c0.22-0.41,0.66-0.37,1.01-0.51l173.15,31.24c2.11,0.38,2.13,0.33,2.8-1.74
c4.15-12.74,8.77-25.3,13.99-37.65C484.14,691.88,484.12,691.88,482.34,690.3z"/>
<path class="st2" d="M483.59,485.97c-38.53,16.43-80.6,30.49-125.44,41.71l169.76,83.17c2.49,1.22,2,1.62,3.93-0.83
c4.34-5.51,8.86-10.85,13.59-16.03c2.73-2.99,2.48-1.91,0.55-5.11L483.59,485.97z"/>
</g>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="22.3537" y1="225.9144" x2="210.229" y2="585.7793">
<stop  offset="0" style="stop-color:#C7C72B"/>
<stop  offset="1" style="stop-color:#FFFFFF"/>
</linearGradient>
<path class="st3" d="M84.54,345.07c0.02,0.01,0.03,0.01,0.05,0.02C84.58,345.09,84.57,345.07,84.54,345.07
C84.54,345.07,84.54,345.07,84.54,345.07z"/>

<linearGradient id="SVGID_00000094614877843905683360000011047098943015217594_" gradientUnits="userSpaceOnUse" x1="469.9788" y1="-10.4272" x2="1057.8627" y2="1115.6317">
<stop  offset="0" style="stop-color:#C7C72B"/>
<stop  offset="1" style="stop-color:#FFFFFF"/>
</linearGradient>
<path style="fill:url(#SVGID_00000094614877843905683360000011047098943015217594_);" d="M660.85,355.38
c0.13,0.21,0.31,0.37,0.48,0.54C661.18,355.74,661.02,355.56,660.85,355.38C660.86,355.38,660.86,355.38,660.85,355.38z"/>

<linearGradient id="SVGID_00000105403066346872528460000015069040741367576737_" gradientUnits="userSpaceOnUse" x1="83.9755" y1="425.7197" x2="671.7012" y2="425.7197">
<stop  offset="0" style="stop-color:#C7C72B"/>
<stop  offset="1" style="stop-color:#CECB4C"/>
</linearGradient>
<path style="fill:url(#SVGID_00000105403066346872528460000015069040741367576737_);" d="M666.04,362.86
c-0.45-0.8-0.93-1.6-1.45-2.38c-0.68-1.49-1.59-2.83-2.77-3.97l-0.04-0.05c-0.02-0.28-0.45-0.54-0.45-0.54
c-0.17-0.17-0.36-0.33-0.48-0.54c0,0,0,0,0,0c0.16,0.18,0.32,0.36,0.48,0.54c-0.05-0.28-0.21-0.46-0.48-0.55
c-0.06-0.27-0.23-0.46-0.5-0.54c-0.06-0.28-0.73-1-1.01-1.07c-0.06-0.29-0.79-1.04-1.07-1.17c0,0,0,0,0,0
c-6.01-6.31-13.5-9.41-22.08-10.15c-6.11-0.53-12.22-1.13-18.32-1.72c0,0-470.06-46.09-476.15-46.78
c-6.3-0.72-12.59-0.9-18.84,0.24c-13.66,2.51-24.69,9.14-32.14,21.09c-3.56,5.71-5.85,11.92-6.22,18.72
c-0.74,3.69-0.78,7.39,0,11.08c-0.01,0-0.03-0.01-0.04-0.01c-0.08,0.17-0.07,0.33,0.05,0.49c0.03,0.35,0.06,0.69,0.09,1.04
c0.05,0.86,0.04,1.72,0.15,2.57l27.05,208.93c14.33-0.09,28.78-0.47,43.34-1.14c54.04-2.5,106.19-8.91,155.53-18.65l-83.39-73.86
c0.19-0.19,0.38-0.38,0.56-0.57l130.26,63.82c44.84-11.23,86.91-25.29,125.44-41.71l-46.09-76.02c0.19-0.16,0.38-0.33,0.57-0.49h0
c0,0,36.08,30.91,74.16,63.56C576.24,442.31,628.87,404.77,666.04,362.86z M84.54,345.07C84.54,345.07,84.54,345.07,84.54,345.07
c0.02-0.01,0.03,0.02,0.05,0.02C84.58,345.09,84.56,345.08,84.54,345.07z"/>

<linearGradient id="SVGID_00000011726492928392631420000015356362014842273207_" gradientUnits="userSpaceOnUse" x1="298.1721" y1="211.0923" x2="606.1718" y2="1057.3145">
<stop  offset="0" style="stop-color:#C6C600"/>
<stop  offset="1" style="stop-color:#FFFFFF"/>
</linearGradient>
<path style="fill:url(#SVGID_00000011726492928392631420000015356362014842273207_);" d="M666.04,362.86
c-0.45-0.8-0.93-1.6-1.45-2.38c-0.68-1.49-1.59-2.83-2.77-3.97l-0.04-0.05c-0.02-0.28-0.45-0.54-0.45-0.54
c-0.17-0.17-0.36-0.33-0.48-0.54c0,0,0,0,0,0c0.16,0.18,0.32,0.36,0.48,0.54c-0.05-0.28-0.21-0.46-0.48-0.55
c-0.06-0.27-0.23-0.46-0.5-0.54c-0.06-0.28-0.73-1-1.01-1.07c-0.06-0.29-0.79-1.04-1.07-1.17c0,0,0,0,0,0
c-6.01-6.31-13.5-9.41-22.08-10.15c-6.11-0.53-12.22-1.13-18.32-1.72c0,0-470.06-46.09-476.15-46.78
c-6.3-0.72-12.59-0.9-18.84,0.24c-13.66,2.51-24.69,9.14-32.14,21.09c-3.56,5.71-5.85,11.92-6.22,18.72
c-0.74,3.69-0.78,7.39,0,11.08c-0.01,0-0.03-0.01-0.04-0.01c-0.08,0.17-0.07,0.33,0.05,0.49c0.03,0.35,0.06,0.69,0.09,1.04
c0.05,0.86,0.04,1.72,0.15,2.57l27.05,208.93c14.33-0.09,28.78-0.47,43.34-1.14c54.04-2.5,106.19-8.91,155.53-18.65l-83.39-73.86
c0.19-0.19,0.38-0.38,0.56-0.57l130.26,63.82c44.84-11.23,86.91-25.29,125.44-41.71l-46.09-76.02c0.19-0.16,0.38-0.33,0.57-0.49h0
c0,0,36.08,30.91,74.16,63.56C576.24,442.31,628.87,404.77,666.04,362.86z M84.54,345.07C84.54,345.07,84.54,345.07,84.54,345.07
c0.02-0.01,0.03,0.02,0.05,0.02C84.58,345.09,84.56,345.08,84.54,345.07z"/>
<g>
<path class="st7" d="M794.6,521.97h65.56v119.26l47.91-55.59h78.96L927,644.28l63.51,108.74h-72.28l-33.9-67.09l-24.17,23.6v43.5
H794.6V521.97z"/>
<path class="st7" d="M1175,685.25h-128.61c1.16,10.3,3.94,17.97,8.35,23.01c6.2,7.25,14.29,10.88,24.27,10.88
c6.31,0,12.3-1.58,17.97-4.73c3.46-1.99,7.19-5.52,11.19-10.56l63.2,5.83c-9.67,16.81-21.33,28.87-34.99,36.17
c-13.66,7.3-33.26,10.95-58.79,10.95c-22.17,0-39.61-3.13-52.33-9.38c-12.72-6.25-23.25-16.18-31.6-29.79
c-8.35-13.6-12.53-29.6-12.53-47.99c0-26.16,8.38-47.33,25.14-63.52c16.76-16.18,39.9-24.27,69.43-24.27
c23.96,0,42.87,3.62,56.74,10.87c13.87,7.25,24.43,17.76,31.68,31.52c7.25,13.76,10.88,31.68,10.88,53.74V685.25z M1109.75,654.52
c-1.26-12.4-4.6-21.28-10.01-26.64c-5.41-5.36-12.53-8.04-21.36-8.04c-10.19,0-18.33,4.04-24.43,12.13
c-3.89,5.04-6.36,12.56-7.41,22.54H1109.75z"/>
<path class="st7" d="M1194.79,585.65h59.73v27.26c8.93-11.13,17.97-19.09,27.11-23.88c9.14-4.78,20.28-7.17,33.41-7.17
c17.76,0,31.65,5.28,41.69,15.84c10.04,10.56,15.05,26.87,15.05,48.94v106.38h-64.46v-92.04c0-10.51-1.94-17.94-5.83-22.3
c-3.89-4.36-9.35-6.54-16.39-6.54c-7.78,0-14.08,2.94-18.91,8.83c-4.83,5.88-7.25,16.45-7.25,31.68v80.38h-64.15V585.65z"/>
<path class="st8" d="M1389.05,706.53l63.67-5.99c2.62,7.57,6.3,12.98,11.03,16.23c4.73,3.26,11.03,4.89,18.91,4.89
c8.61,0,15.29-1.84,20.02-5.51c3.68-2.73,5.52-6.14,5.52-10.23c0-4.61-2.42-8.18-7.25-10.7c-3.47-1.79-12.66-3.99-27.58-6.61
c-22.27-3.88-37.75-7.48-46.41-10.78c-8.67-3.31-15.97-8.9-21.91-16.77c-5.94-7.87-8.91-16.85-8.91-26.92
c0-11.02,3.2-20.52,9.62-28.5c6.41-7.98,15.23-13.93,26.48-17.87c11.24-3.94,26.32-5.91,45.23-5.91c19.96,0,34.7,1.52,44.21,4.57
c9.51,3.05,17.44,7.78,23.8,14.19c6.36,6.41,11.63,15.08,15.84,26l-60.84,5.99c-1.58-5.36-4.2-9.3-7.88-11.82
c-5.04-3.36-11.14-5.04-18.28-5.04c-7.25,0-12.53,1.28-15.84,3.86c-3.31,2.57-4.96,5.69-4.96,9.36c0,4.09,2.1,7.18,6.31,9.28
c4.2,2.1,13.34,3.99,27.42,5.67c21.33,2.41,37.19,5.77,47.6,10.08c10.4,4.3,18.36,10.44,23.88,18.42
c5.52,7.98,8.27,16.74,8.27,26.29c0,9.66-2.91,19.05-8.75,28.18c-5.83,9.13-15.03,16.4-27.58,21.81
c-12.56,5.4-29.65,8.11-51.3,8.11c-30.58,0-52.35-4.36-65.33-13.08C1401.05,735.01,1392.73,722.61,1389.05,706.53z"/>
<path class="st8" d="M1580,669.81c0-25.53,8.61-46.57,25.85-63.12c17.23-16.55,40.5-24.82,69.82-24.82
c33.51,0,58.84,9.72,75.97,29.15c13.76,15.66,20.65,34.94,20.65,57.84c0,25.74-8.54,46.84-25.61,63.28
c-17.08,16.45-40.69,24.67-70.84,24.67c-26.9,0-48.65-6.83-65.25-20.49C1590.19,719.41,1580,697.23,1580,669.81z M1644.31,669.65
c0,14.92,3.02,25.95,9.06,33.1c6.04,7.15,13.63,10.72,22.77,10.72c9.25,0,16.84-3.52,22.78-10.56c5.93-7.04,8.9-18.33,8.9-33.88
c0-14.5-3-25.3-8.98-32.39c-5.99-7.09-13.39-10.64-22.22-10.64c-9.35,0-17.08,3.6-23.17,10.8
C1647.35,643.99,1644.31,654.94,1644.31,669.65z"/>
<path class="st8" d="M1795.7,521.97h64.3v231.05h-64.3V521.97z"/>
</g>
</g>
</svg>
</template>

<script>
  export default {}
</script>

<style scoped lang="scss">
  .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#C5C500;}
  .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
  .st2{fill:#C5C500;}
  .st3{fill:url(#SVGID_1_);}
  .st4{fill:url(#SVGID_00000020359456517849249220000001044125572383123620_);}
  .st5{fill:url(#SVGID_00000097495907677501528370000015965842952335725488_);}
  .st6{fill:url(#SVGID_00000034777851375011899860000015891309826714856106_);}
  .st7{fill:#575756;}
  .st8{fill:#C2C31D;}
</style>
