import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/assets/css/main.scss";
import Amplify, { Auth } from "aws-amplify";
import Notifications from "@kyvg/vue3-notification";
import Vue3Signature from "vue3-signature"
import UUID from "vue3-uuid";
import i18n from './i18n';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "izzifast-installer-app",
        endpoint: "https://ezog1d2mz4.execute-api.eu-west-2.amazonaws.com/prod",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

const app = createApp(App)

app.use(store)
  .use(router)
  .use(Notifications)
  .use(Vue3Signature)
  .use(UUID)
  .use(i18n)
  .mount("#app");

app.config.globalProperties.$filters = {
  colorizeDevice(device, index) {
    const today = new Date()
    const created = new Date(device.deviceExtension.createdAt)
    const limitClose = new Date(today.getTime() - (10 * 24 * 60 * 60000))
    const limitPassed = new Date(today.getTime() - (15 * 24 * 60 * 60000))
    const deadlineIncoming = created.getTime() < limitClose.getTime()
    const deadlinePassed = created.getTime() < limitPassed.getTime()
    if (index % 2 === 0){
      if (device.deviceExtension.sent) return 'bg-green-50'
      else if (deadlinePassed) return 'bg-red-50'
      else if (deadlineIncoming) return 'bg-yellow-50'
      else return 'bg-white'
    } else {
      if (device.deviceExtension.sent) return 'bg-green-100'
      else if (deadlinePassed) return 'bg-red-100'
      else if (deadlineIncoming) return 'bg-yellow-100'
      else return 'bg-gray-50'
    }
  }
}
