
import { createI18n } from 'vue-i18n'
import pl from './assets/i18n/pl.json'
import en from './assets/i18n/en.json'
import de from './assets/i18n/de.json'


const i18n = createI18n({
  locale: (localStorage.getItem('lang') || 'pl'),
  fallbackLocale: 'pl',
  messages: {
    en: en,
    de: de,
    pl: pl
  }
})

export default i18n
