import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logos from './logosB64.js'
import warrantyText from './warrantyTexts.js'
import { attachTemplate, serializeParams } from '@/assets/js/helpers.js'
import i18n from '@/i18n.js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function generateWarrantyPdf (
  installation,
  showDataTable = true,
  tableParameters = null,
  content = null,
  showServiceLog = true,
  lang
) {


  const t = i18n.global.t

  const device = serializeParams(attachTemplate(installation.customDevice))
  const installationDate = new Date(installation.installationDate).toLocaleDateString('pl-PL')

  let deviceData
  if (device.parameters) {
    let fields = device.schemaVersion.parametersSchema
    if (tableParameters) fields = fields.filter(field => {return tableParameters.includes(field.name)})
    deviceData = fields.map(field => {
      return [field.labels?.[lang] ? field.labels[lang] : field.label, device.parameters[field.name]]
    }).filter(value => { return value[1] })
    if (device.step) {
      deviceData.unshift(['Numer seryjny szafy hydraulicznej', device.id])
      deviceData.unshift(['Numer seryjny', ''])
    }
    deviceData.unshift([t('pdf.technical.deviceType', lang), device.deviceTemplate.nameTranslations?.[lang] ? device.deviceTemplate.nameTranslations[lang] : device.deviceTemplate.name])
  }

  const docDefinition = {
    content: [
      {
        image: logos[process.env.VUE_APP_ORG_ID],
        width: 200,
        alignment: 'right'
      },
      {
        text: t('installation.warrantyCard', lang),
        alignment: 'left',
        margin: [0, 10],
        style: 'header'
      },
      showDataTable ? {
        // style: 'tableExample',
        margin: [0, 0, 0, 20],
        table: {
          widths: [200, 300],
          heights: [...deviceData.map(() => {return 12}), 12, 12, 24, 52],
          body: [
            ...deviceData,
            [t('pdf.warranty.saleDate', lang), ''],
            [t('pdf.warranty.installationDate', lang), installationDate],
            [t('pdf.warranty.installationAddress', lang),
              `${installation.customerAddress}
               ${installation.customerZipcode} ${installation.customerCity}`
            ],
            [t('pdf.warranty.dealerSignature', lang), ''],
          ]
        }
      } : null,
      content ? content : warrantyText(process.env.VUE_APP_ORG_ID, device.heatpump),
      showServiceLog ? {text: t('pdf.warranty.serviceLog', lang), style: 'header', margin: [0, 20, 0, 8], pageBreak: 'before'} : null,
      showServiceLog ? {
        table: {
          widths: [100, 220, 160],
          heights: [20, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,],
          body: [
            [t('pdf.warranty.protocolNo', lang), t('pdf.warranty.description', lang), t('pdf.warranty.signature', lang)],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
            ['', '', ''],
          ]
        },
        layout: {
          fillColor: function (rowIndex) {
            return (rowIndex % 2 === 0 && rowIndex !== 0) ? process.env.VUE_APP_MAIN_COLOR : null
          },
          fillOpacity: function (rowIndex) {
            return (rowIndex % 2 === 0 && rowIndex !== 0) ? 0.3 : 1
          }
        }
      } : null
    ],
    defaultStyle: {
      fontSize: 10,
    },
    styles: {
       header: {
         fontSize: 16,
         bold: true
       },
       subheader: {
         fontSize: 14,
         bold: true
       },
       quote: {
         italics: true
       },
       medium: {
         fontSize: 9
       },
       small: {
         fontSize: 8
       },
       mb: {
          margin: [0, 0, 5, 0]
       }
    },
    pageBreakBefore: function(currentNode) {
      return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
    }
  }

  const pdfDocGenerator = pdfMake.createPdf(docDefinition)
  // pdfDocGenerator.open();
  return new Promise( resolve => {
    pdfDocGenerator.getBase64((file) => {
      resolve(file)
    });
  });

}

export default generateWarrantyPdf;